import { useState, useEffect } from 'react'
import { AxiosResponse } from 'axios'

import { filterByOnlySelectedVerses, getVerseDetails, IBibleTextObj } from '../../utils/scripture-modal/bible-utils'
import { fetchBibleChapter } from '../../services/bible'
import { INewBibleResponse, INewVerse } from '../../utils/types'

const useScriptureModal = (scriptureText: string) => {
	const [loading, setLoading] = useState(true)
	const [allVerses, setAllVerses] = useState<INewVerse[]>([])
	const [verses, setVerses] = useState<INewVerse[]>([])
	const [isSelectedVerses, setIsSelectedVerses] = useState(true)
	const [bibleTextObj, setBibleTextObj] = useState<IBibleTextObj>({} as IBibleTextObj)

	useEffect(() => {
		if (scriptureText) {
			fetchBibleVerses(scriptureText)
		}
	}, [scriptureText])

	const fetchBibleVerses = async (text: string) => {
		setLoading(true)
		setIsSelectedVerses(true)

		try {
			const textObj = getVerseDetails(text)
			setBibleTextObj(textObj)

			const res = (await fetchBibleChapter(textObj.bookId, textObj.chapter)) as unknown as AxiosResponse<INewBibleResponse>

			setAllVerses(res.data.content)
			setVerses(filterByOnlySelectedVerses(res.data.content, textObj.verses))
		} catch (error) {
			console.error(`Error fetching Bible verses: ${error}`)
		} finally {
			setLoading(false)
		}
	}

	const getSelectedBibleVerses = () => {
		setVerses(filterByOnlySelectedVerses(allVerses, bibleTextObj.verses))
		setIsSelectedVerses(true)
	}

	const getFullChapter = () => {
		setVerses(allVerses)
		setIsSelectedVerses(false)
	}

	return { loading, verses, getSelectedBibleVerses, getFullChapter, isSelectedVerses, bibleTextObj }
}

export default useScriptureModal
