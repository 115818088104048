import styled from 'styled-components'
import useScriptureModal from '../../hooks/scripture-modal/scripture-modal'

interface IScriptureModalProps {
	isModalOpen: boolean
}

const ScriptureModalWrapper = styled.div<IScriptureModalProps>`
	display: ${(props) => (props.isModalOpen ? 'block' : 'none')};
	overflow: hidden;
	position: fixed;
	bottom: 0;
	width: 100%;
	background: #222222;
	border-radius: 16px 16px 0px 0px;
	z-index: 101;
`

const ScriptureModalContainer = styled.div<{ isSelectedVerses: boolean }>`
	height: ${(props) => (props.isSelectedVerses ? '280px' : '380px')};
	padding: 32px 24px 0 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	transition: height 0.5s ease-in-out;

	& > .scripture-modal--close-icon {
		position: absolute;
		display: flex;
		align-self: flex-end;
		justify-content: center;
		align-items: center;
		width: 36px;
		min-height: 36px;
		background: #ffffff;
		border-radius: 50px;
		box-shadow: -2px 6px 44px 2px rgba(0, 0, 0, 0.08);
		margin-right: 20px;
	}

	& > svg {
		position: absolute;
		bottom: 0;
		right: 0;
	}
`

const BibleTextLoader = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4px;

	& > svg {
		margin-bottom: 16px;
	}

	& > img {
		width: 64px;
		height: 64px;
		object-fit: cover;
		margin-bottom: 16px;
	}

	& > p {
		font-weight: 400;
		font-size: 16px;
		line-height: 144%;
		text-align: center;
		color: #ffffff;
	}
`

const BibleTextContainer = styled.div`
	width: 100%;
	max-width: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2rem;
	overflow: hidden;

	& > span {
		color: aliceblue;
		font-size: 13px;
		text-decoration: underline;
		text-transform: uppercase;
		cursor: pointer;
		width: fit-content;
	}
`

const BibleTextBox = styled.div`
	width: 100%;
	max-width: 600px;
	margin-top: 2px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	color: #ffffff;
	z-index: 20;
	overflow-y: auto;

	& > h6 {
		width: 100%;
		font-family: 'Clearface';
		font-weight: 100;
		font-size: 18px;
		line-height: 120%;
		color: #ffffff;
		margin-bottom: 16px;
	}

	& > div {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		overflow-y: auto;
		max-height: 240px;
		padding-right: 8px;
	}

	& > div > p {
		font-weight: 500;
		font-size: 16px;
		line-height: 144%;
		color: #ffffff;
		margin-bottom: 14px;
	}
`

interface IScriptureModal {
	scriptureText: string
	isOpen: boolean
	handleModalClose: (e: any) => void
}

const ScriptureModal: React.FunctionComponent<IScriptureModal> = ({ scriptureText, isOpen, handleModalClose }) => {
	const { loading, verses, isSelectedVerses, bibleTextObj, getFullChapter, getSelectedBibleVerses } = useScriptureModal(scriptureText)

	return (
		<ScriptureModalWrapper isModalOpen={isOpen}>
			<ScriptureModalContainer isSelectedVerses={isSelectedVerses}>
				<div className='scripture-modal--close-icon' onClick={() => handleModalClose(false)}>
					<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M15.625 4.375L4.375 15.625' stroke='#052B4E' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M15.625 15.625L4.375 4.375' stroke='#052B4E' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</div>

				{loading ? (
					<BibleTextLoader>
						<img src='/assets/images/simple_loader.gif' alt='' />

						<p>Opening Bible Verse</p>
					</BibleTextLoader>
				) : (
					<BibleTextContainer>
						{isSelectedVerses ? (
							<span onClick={getFullChapter}>View full chapter</span>
						) : (
							<span onClick={getSelectedBibleVerses}>View selected verses</span>
						)}

						<BibleTextBox>
							{isSelectedVerses ? (
								<h6>{scriptureText} (KJV)</h6>
							) : (
								<h6>
									{bibleTextObj.bookName} {bibleTextObj.chapter} (KJV)
								</h6>
							)}
							<div>
								{verses &&
									verses.map((verse) => (
										<p key={verse.v}>
											{verse.v}. {verse.t}
										</p>
									))}
							</div>
						</BibleTextBox>
					</BibleTextContainer>
				)}

				<svg width='74' height='189' viewBox='0 0 74 189' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<g opacity='0.2'>
						<circle cx='107.478' cy='139.522' r='106.978' fill='#D9D9D9' fillOpacity='0.15' stroke='white' />
						<circle cx='128.84' cy='128.841' r='106.978' stroke='white' />
						<circle cx='139.522' cy='107.478' r='106.978' stroke='white' />
					</g>
				</svg>
			</ScriptureModalContainer>
		</ScriptureModalWrapper>
	)
}

export default ScriptureModal
