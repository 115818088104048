import styled from 'styled-components'

import CatchUpCard from '../../general/CatchUpCard'
import SendWhatsAppMessageCard from '../../general/SendWhatsAppMessageCard'
import TopicManualsList from './TopicManualsList'
import TopicManualsListSoon from '../molecules/TopicManualsListSoon'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect } from 'react'
import { IManual } from '../../../utils/types'

const ThemeManualsListContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 32px;
`

interface IThemeManualsList {
	loading: boolean
	// themes: string[]
	setPageNumber: (e: number) => void
	allManuals: IAllManualsByThemes[]
}

interface IAllManualsByThemes {
	theme: string
	manuals: IManual[]
}

const ThemeManualsList = ({ loading, setPageNumber, allManuals }: IThemeManualsList) => {
	useEffect(() => {
		// console.log(allManuals)
	}, [allManuals])

	return (
		<ThemeManualsListContainer>
			{loading && [1, 2, 3, 4].map((el) => <Skeleton height={64} style={{ marginBottom: '16px' }} key={el} />)}
			{!loading &&
				allManuals &&
				allManuals?.length > 0 &&
				allManuals.map(
					(manualsByTheme: any) =>
						manualsByTheme.count > 0 && (
							<TopicManualsList
								key={manualsByTheme.theme}
								theme={manualsByTheme.theme}
								count={manualsByTheme.count}
								manuals={manualsByTheme.manuals}
							/>
						)
				)}

			{!loading && (
				<>
					<TopicManualsListSoon manualTheme='Male & Female Created He Them' numberOfLessons='12' />
					<TopicManualsListSoon manualTheme='In Search of Timothy' numberOfLessons='30' />
				</>
			)}

			{/* <SendWhatsAppMessageCard />

			<CatchUpCard /> */}
		</ThemeManualsListContainer>
	)
}

export default ThemeManualsList
