import styled from 'styled-components'

import { useManualLibrary } from '../../../hooks/useManualLibrary'
// import { useSortModal } from '../../../hooks/useSortModal'
// import SortBottomModal from '../../molecules/SortBottomModal'
import AllManualsList from './AllManualsList'
import ThemeManualsList from './ThemeManualsList'

const ManualLibraryContainer = styled.div`
	display: flex;
	flex-direction: column;
	// background: red;
	width: 100%;
	padding: 0 20px;
	margin: 30px 0;

	@media (min-width: 768px) {
		padding: 0 60px;
	}

	@media (min-width: 1024px) {
		width: 906px;
	}
`

const ManualLibraryTabsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	border-bottom: 1px solid #ddd9d9;

	& > .filter-icon {
		min-width: 44px;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: -2px 6px 44px 2px rgba(0, 0, 0, 0.08);
	}
`

const ManualLibraryTabs = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	& > p {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 140%;
		color: #9f9f9f;
		padding: 11px 0 24px;
		margin: 0;
		transition: all 0.2s ease-in-out;
		border-bottom: 2px solid transparent;
		width: 140px;
	}

	& > p.active {
		font-weight: 700;
		color: #9f0e02;
		border-bottom: 2px solid #9f0e02;
	}

	& > p:nth-child(2) {
		padding-left: 28px;
	}

	@media (min-width: 1024px) {
		& > p {
			cursor: pointer;
		}
	}
`

const ManualLibrary = () => {
	// const [sortModal, setSortModal] = useState(false) // this should be in all manuals list

	const {
		activeTab,
		setActiveTab,
		themesLoading,
		// themes,
		setThemesPageNumber,
		allManualsLoading,
		allManualsLoadingMore,
		manuals,
		lastPage,
		manualsPageNumber,
		setManualsPageNumber,
		allManualsByTheme,
	} = useManualLibrary()

	// const { sortModal, setSortModal, closeModal } = useSortModal()

	return (
		<ManualLibraryContainer>
			<ManualLibraryTabsContainer>
				<ManualLibraryTabs>
					<p className={activeTab === 'manual-theme' ? 'active' : ''} onClick={() => setActiveTab('manual-theme')}>
						Manuals By Theme
					</p>
					<p className={activeTab === 'manual-all' ? 'active' : ''} onClick={() => setActiveTab('manual-all')}>
						All Manuals
					</p>
				</ManualLibraryTabs>

				{/* <div className='filter-icon' onClick={() => setSortModal(!sortModal)}>
					<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M10 9.375V16.875' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M10 3.125V6.875' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M15.625 15.625V16.875' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M15.625 3.125V13.125' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M17.5 13.125H13.75' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M4.375 13.125V16.875' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M4.375 3.125V10.625' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M2.5 10.625H6.25' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M11.875 6.875H8.125' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</div> */}
			</ManualLibraryTabsContainer>

			{activeTab === 'manual-theme' && (
				<ThemeManualsList
					loading={themesLoading}
					setPageNumber={(e: number) => setThemesPageNumber(e)}
					allManuals={allManualsByTheme ? allManualsByTheme : []}
				/>
			)}

			{activeTab === 'manual-all' && (
				<AllManualsList
					loading={allManualsLoading}
					allManualsLoadingMore={allManualsLoadingMore}
					manuals={manuals ? manuals : []}
					lastPage={lastPage}
					pageNumber={manualsPageNumber}
					setPageNumber={(e) => setManualsPageNumber(e)}
				/>
			)}

			{/* <SortBottomModal isOpen={sortModal} handleModalClose={(e) => closeModal(e)} /> */}
		</ManualLibraryContainer>
	)
}

export default ManualLibrary
