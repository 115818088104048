import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { IManual } from '../../../utils/types'

import { getAllManualsByTheme } from '../../../services/manuals'

import ManualCardSmallSkeleton from '../../general/atoms/ManualCardSmallSkeleton'
import ManualCardSmall from '../../general/atoms/ManualCardSmall'

const ExploreMoreManualsContainer = styled.div`
	padding: 0 25px 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
	// align-items: center;
	// background: teal;

	// SMALL PHONES
	@media (max-width: 390px) {
		// width: 713px;
		padding: 0 10px 10px;
	}

	// TABS
	@media (min-width: 768px) {
		width: 713px;
		padding: 0 0 40px;
	}

	// DESKTOPS
	@media (min-width: 1024px) {
		width: 906px;
	}
`

const ExploreMoreManualsHeader = styled.h5`
	width: 237px;
	margin: 0 auto 32px;
	font-family: 'Apercu';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 140%;
	text-align: center;
	color: #9f0e02;

	// TABS
	@media (min-width: 768px) {
		width: 100%;
		margin: 0 auto 24px;
	}

	// DESKTOPS
	@media (min-width: 1024px) {
		padding: 32px 80px 40px;
	}
`

const ExploreMoreManuals = () => {
	const [moreManuals, setMoreManuals] = useState<IManual[]>()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		let currentTheme = localStorage.getItem('currentTheme')

		if (currentTheme) {
			getAllManualsByTheme(currentTheme, 1).then((res) => {
				setMoreManuals(res.manuals.slice(1, 4))
				setLoading(false)
			})

			// how to set the explore more manuals...??
		}
	}, [])

	return (
		<ExploreMoreManualsContainer>
			{!loading && (moreManuals?.length as Number) > 0 && (
				<ExploreMoreManualsHeader>Explore More Manuals In This Theme</ExploreMoreManualsHeader>
			)}

			{loading && [1, 2, 3].map((e) => <ManualCardSmallSkeleton key={e} />)}

			{!loading && moreManuals?.map((manual) => <ManualCardSmall {...manual} key={manual.id} />)}
		</ExploreMoreManualsContainer>
	)
}

export default ExploreMoreManuals
