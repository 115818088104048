import Layout from '../layouts/Layout'

import PrivacyPolicyHeader from '../components/privacy/PrivacyPolicyHeader'
import PrivacyPolicyBody from '../components/privacy/PrivacyPolicyBody'

const PrivacyPolicy = () => {
	return (
		<Layout>
			<PrivacyPolicyHeader />
			<PrivacyPolicyBody />
		</Layout>
	)
}

export default PrivacyPolicy
