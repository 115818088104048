import styled from 'styled-components'

import { IManual } from '../../../utils/types'

import ManualCard from './ManualCard'
import useCarousel from '../../../hooks/carousel/carousel'

const ClickCarouselContainer = styled.div`
	width: 100%;
	height: 695px;
	background: #fe5818;
	margin: 0 0 26px 0;

	display: flex;
	flex-direction: column;
	align-items: center;

	& > h5 {
		margin: 36px 0 12px;
		font-family: 'Clearface';
		font-style: normal;
		font-weight: 100;
		font-size: 2rem;
		line-height: 120%;
		text-align: center;
		color: #ffffff;
	}

	& > p {
		width: 235px;
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 144%;
		text-align: center;
		color: #ffffff;
	}
`

const ClickCarouselGallery = styled.div`
	margin-top: 46px;
	width: 100%;
	overflow: hidden;
	align-self: flex-start;
`

const ClickCarouselGalleryScroller = styled.div`
	width: 100%;
	height: 430px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	// padding: 0 32px;
	padding: 0 calc((100% - 328px) / 2);
	// transform: translateX(-100%) translateX(50%) translateX(-125px);
	transition: all 0.2s ease-in-out;

	@media (max-width: 340px) {
		padding: 0 calc((100% - 300px) / 2);
	}
`

const CarouselPagination = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 30px auto;

	& > p {
		margin: 0 14px;
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 120%;
		color: #ffffff;
		width: 43px;
	}

	& > svg {
		transform: rotate(180deg);
		transition: all 0.2s ease-in-out;
		border-radius: 4px;
	}

	& > svg.active-carousel-nav {
		fill: white;
		background: white;
	}

	& > svg:nth-child(1) {
		transform: rotate(0);
	}
`

const ClickCarousel = ({ manuals }: { manuals: IManual[] }) => {
	const { cardViewNum, goLeftNav, goRightNav, handleTouchMove, handleTouchStart } = useCarousel({ manuals })

	return (
		<ClickCarouselContainer>
			<h5>Recently taught manuals</h5>
			<p>The last {manuals.length} manuals taught by the sunday school department.</p>

			<ClickCarouselGallery>
				<ClickCarouselGalleryScroller className='gallery-scroller' onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
					{manuals.map((manual, idx) => (
						<ManualCard isActive={cardViewNum === idx + 1} {...manual} key={manual.id} />
					))}
				</ClickCarouselGalleryScroller>
			</ClickCarouselGallery>

			<CarouselPagination>
				<svg
					onClick={goLeftNav}
					className={cardViewNum > 1 ? 'active-carousel-nav' : ''}
					width='35'
					height='34'
					viewBox='0 0 35 34'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M12 17.0007C11.9999 16.8589 12.0299 16.7187 12.0881 16.5894C12.1463 16.4601 12.2313 16.3446 12.3375 16.2507L21.3375 8.2507C21.436 8.1637 21.5507 8.09695 21.6749 8.05427C21.7992 8.01158 21.9307 7.99379 22.0619 8.00191C22.193 8.01004 22.3213 8.04392 22.4394 8.10162C22.5575 8.15932 22.663 8.23971 22.75 8.3382C22.837 8.43669 22.9038 8.55136 22.9464 8.67565C22.9891 8.79993 23.0069 8.93141 22.9988 9.06257C22.9907 9.19374 22.9568 9.32202 22.8991 9.44008C22.8414 9.55815 22.761 9.6637 22.6625 9.7507L14.5 17.0007L22.6625 24.2507C22.8149 24.3851 22.9228 24.5628 22.9717 24.76C23.0206 24.9572 23.0082 25.1647 22.9363 25.3548C22.8643 25.5448 22.7362 25.7084 22.5689 25.8238C22.4017 25.9392 22.2032 26.0009 22 26.0007C21.7563 25.9998 21.5211 25.9111 21.3375 25.7507L12.3375 17.7507C12.2313 17.6568 12.1463 17.5413 12.0881 17.412C12.0299 17.2827 11.9999 17.1425 12 17.0007Z'
						fill='#444444'
					/>
				</svg>

				<p>
					{cardViewNum} of {manuals.length}
				</p>

				<svg
					onClick={goRightNav}
					className={cardViewNum < 4 ? 'active-carousel-nav' : ''}
					width='35'
					height='34'
					viewBox='0 0 35 34'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M12 17.0007C11.9999 16.8589 12.0299 16.7187 12.0881 16.5894C12.1463 16.4601 12.2313 16.3446 12.3375 16.2507L21.3375 8.2507C21.436 8.1637 21.5507 8.09695 21.6749 8.05427C21.7992 8.01158 21.9307 7.99379 22.0619 8.00191C22.193 8.01004 22.3213 8.04392 22.4394 8.10162C22.5575 8.15932 22.663 8.23971 22.75 8.3382C22.837 8.43669 22.9038 8.55136 22.9464 8.67565C22.9891 8.79993 23.0069 8.93141 22.9988 9.06257C22.9907 9.19374 22.9568 9.32202 22.8991 9.44008C22.8414 9.55815 22.761 9.6637 22.6625 9.7507L14.5 17.0007L22.6625 24.2507C22.8149 24.3851 22.9228 24.5628 22.9717 24.76C23.0206 24.9572 23.0082 25.1647 22.9363 25.3548C22.8643 25.5448 22.7362 25.7084 22.5689 25.8238C22.4017 25.9392 22.2032 26.0009 22 26.0007C21.7563 25.9998 21.5211 25.9111 21.3375 25.7507L12.3375 17.7507C12.2313 17.6568 12.1463 17.5413 12.0881 17.412C12.0299 17.2827 11.9999 17.1425 12 17.0007Z'
						fill='#444444'
					/>
				</svg>
			</CarouselPagination>
		</ClickCarouselContainer>
	)
}

export default ClickCarousel
