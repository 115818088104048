import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const conf: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_BASE_URL,
	// baseURL: 'https://octopus-app-tzgb8.ondigitalocean.app/api',
}

const request: AxiosInstance = axios.create(conf)

export { request }
