import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { createManualPathName } from '../../../utils/createPathName'
import { getFormattedDate } from '../../../utils/dateFormatter'
import { IManual } from '../../../utils/types'

const LatestManualContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	margin-bottom: 76px;

	& > h5 {
		font-weight: 700;
		font-size: 20px;
		line-height: 140%;
		color: #333333;
		padding: 0 0 16px;
		border-bottom: 1px solid #ddd9d9;
	}

	& > a > .manual-img {
		margin: 24px 0 16px;
		width: 100%;
		height: 180px;
		border: 1px solid #eee8e8;
		border-radius: 6px;
		overflow: hidden;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	& > a > h4 {
		// width: 357px;
		font-family: 'Clearface';
		font-style: normal;
		font-weight: 100;
		font-size: 24px;
		line-height: 144%;
		letter-spacing: -0.005em;
		color: #333333;
		margin-right: 30px;
	}

	@media (min-width: 768px) {
		align-items: center;
		margin: 0 auto 76px;
		width: 100%;
		padding: 0 60px;

		& > h5 {
			width: 100%;
			max-width: 904px;
			text-align: center;
		}

		& > a {
			width: 100%;
			max-width: 904px;
			margin: 8px 0 16px;
		}

		& > a > .manual-img {
			margin: 0;
			height: 254px;
		}

		& > a > h4 {
			text-align: center;
			font-size: 28px;
			line-height: 148%;
		}
	}
`

const MetaDetails = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 12px;

	& > span {
		width: 6px;
		height: 6px;
		background-color: #bbb;
		border-radius: 50%;
		display: inline-block;
		background: #222222;
		margin: 0 16px;
	}

	@media (min-width: 768px) {
		margin: 12px 0 16px;
	}
`

const MetaDetail = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	& > svg {
		width: 20px;
		margin-right: 4px;
	}

	& > p {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
		color: #111111;
	}

	@media (min-width: 768px) {
		& > p {
			font-size: 16px;
			line-height: 19px;
		}
	}
`

const ManualActions = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px 0 20px;
	border-bottom: 1px solid #ddd9d9;

	& > a {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #c21901;
		padding: 4px 0;
		display: flex;
		flex-direction: row;
		align-items: center;

		& > svg {
			margin-left: 8px;
		}
	}

	& > div {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #111111;
		padding: 4px;

		& > svg {
			margin-left: 8px;
		}
	}

	@media (min-width: 768px) {
		width: 100%;
		justify-content: center;

		& > a {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 12px 0px;
			width: 442px;
			height: 49px;
			background: #fce5e2;
			border-radius: 4px;

			font-size: 20px;
			line-height: 25px;

			& > svg {
				margin-left: 12px;
			}
		}
	}
`

const LatestManual = (manual: IManual) => {
	const [pathName, setPathName] = useState('')

	useEffect(() => {
		let customPathName = createManualPathName(manual.topic, manual.manual_date, manual.id)
		setPathName(customPathName)
	}, [manual])

	return (
		<LatestManualContainer>
			<h5>Latest Uploaded Manual</h5>
			<Link to={`manual/${pathName}`}>
				<div className='manual-img'>
					<img src={manual.header_image} alt={manual.topic} />
				</div>
			</Link>

			<MetaDetails>
				<MetaDetail>
					<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5Z'
							stroke='#111111'
							strokeMiterlimit='10'
						/>
						<path d='M8 5.5V8H10.5' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
						<path
							d='M5.5 4.25625L5.925 1.9125C5.94559 1.79663 6.0064 1.69174 6.09671 1.61629C6.18703 1.54083 6.30106 1.49966 6.41875 1.5H9.58125C9.69894 1.49966 9.81297 1.54083 9.90329 1.61629C9.9936 1.69174 10.0544 1.79663 10.075 1.9125L10.5 4.25625'
							stroke='#111111'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M5.5 11.7438L5.925 14.0875C5.94559 14.2034 6.0064 14.3083 6.09671 14.3837C6.18703 14.4592 6.30106 14.5003 6.41875 14.5H9.58125C9.69894 14.5003 9.81297 14.4592 9.90329 14.3837C9.9936 14.3083 10.0544 14.2034 10.075 14.0875L10.5 11.7438'
							stroke='#111111'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>

					<p>{manual.read_time} mins read</p>
				</MetaDetail>

				<span></span>

				<MetaDetail>
					<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M13 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V3C13.5 2.72386 13.2761 2.5 13 2.5Z'
							stroke='#111111'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path d='M11 1.5V3.5' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M5 1.5V3.5' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M2.5 5.5H13.5' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
					</svg>

					<p>{getFormattedDate(manual.manual_date)}</p>
				</MetaDetail>
			</MetaDetails>

			<Link to={`manual/${pathName}`}>
				<h4>{manual.topic}</h4>
			</Link>

			<ManualActions>
				<Link to={`manual/${pathName}`}>
					Take Lesson{' '}
					<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M4.375 3.75L10.625 10L4.375 16.25' stroke='#C21901' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M10.625 3.75L16.875 10L10.625 16.25' stroke='#C21901' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</Link>

				{/* <div>
					Share{' '}
					<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M13.75 11.875L17.5 8.125L13.75 4.375'
							stroke='black'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M15 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V6.875'
							stroke='black'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M5.85938 13.75C6.27643 12.1401 7.21619 10.7143 8.53119 9.69621C9.84618 8.67815 11.462 8.1255 13.125 8.125H17.5'
							stroke='black'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</div> */}
			</ManualActions>
		</LatestManualContainer>
	)
}

export default LatestManual
