import ReactDOM from 'react-dom/client'

import './index.css'
import './fonts/Apercu/apercu.css'
import './fonts/Clearface/clearface.css'
import App from './App'
import { ThemeProvider } from './contexts/ThemeContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	// <React.StrictMode>
	<ThemeProvider>
		<App />
	</ThemeProvider>
	// </React.StrictMode>
)
