import { request } from '.'

export const fetchManuals = async () => {
	return await request.get(`/manuals?useManualDate=true&?page=1&isVisible=1`)
}

// take account to sort manual by different option Chibs provided
export const getAllManuals = async (pageNumber?: number, sortBy?: string, filterBy?: string) => {
	return await request.get(`/manuals?useManualDate=true&page=${pageNumber ? pageNumber : 1}&isVisible=1`) // remove the ternary condition
	// return await request.get(`/manuals?useManualDate=true&?page=${pageNumber ? pageNumber : 1}`) // remove the ternary condition
}

export const fetchManualById = async (id: any) => {
	return await request.get(`/manuals/${id}`)
}

export const getAllThemes = async (pageNumber?: number) => {
	return await request.get(`/themes`)
}

export const getAllManualsByTheme = async (theme: string, pageNumber: number) => {
	let response = await request.post(`/themes/manuals?page=${pageNumber ? pageNumber : 1}&useManualDate=true&isVisible=1`, {
		theme,
	})
	// let response = await request.post(`/themes/manuals?page=${pageNumber ? pageNumber : 1}&useManualDate=true`, {
	// 	theme,
	// })

	let manuals = response.data.manuals
	let count = response.data.manuals_count

	return {
		manuals,
		count,
		theme,
	}
}
