import styled from 'styled-components'

import { IManual } from '../../../utils/types'

import CatchUpCard from '../../general/CatchUpCard'
import LoadMoreManualsButton from '../../general/LoadMoreManualsButton'
import ManualCardSmall from '../../general/atoms/ManualCardSmall'
import SendWhatsAppMessageCard from '../../general/SendWhatsAppMessageCard'
import ManualCardSmallSkeleton from '../../general/atoms/ManualCardSmallSkeleton'
import { useEffect } from 'react'

const AllManualsListContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 12px;

	& > p.end-of-manuals-topic-list {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
		text-align: center;
		color: #bebcbc;
		margin: 32px 0 8px;
	}
`

interface IAllManualsList {
	loading: boolean
	allManualsLoadingMore: boolean
	manuals: IManual[]
	lastPage: number
	pageNumber: number
	setPageNumber: (e: any) => any
}

const AllManualsList = ({ loading, allManualsLoadingMore, manuals, lastPage, pageNumber, setPageNumber }: IAllManualsList) => {
	// fetch all manuals here
	// per page is 15 - after every 5 manuals (except the last 5 – or 1 to 5), display one of those cards
	// give manualCardSmall a better name
	// loadMore ManualsButton should fetch next page if there is, and should not even appear if on last page
	// we should see "End of Manuals" if we have gotten to the end of the data

	useEffect(() => {
		// console.log(manuals)
	}, [manuals])

	const handlePageChange = () => {
		pageNumber < lastPage ? setPageNumber((prevValue: number) => prevValue + 1) : setPageNumber((prevValue: any) => prevValue)
	}

	return (
		<>
			<AllManualsListContainer>
				{loading && [1, 2, 3].map((el) => <ManualCardSmallSkeleton key={el} />)}

				{!loading && manuals?.map((manual) => <ManualCardSmall {...manual} key={manual.id} />)}

				{/* <SendWhatsAppMessageCard /> */}
				{/* <CatchUpCard /> */}

				{!loading && pageNumber !== lastPage && <LoadMoreManualsButton onClick={handlePageChange} />}

				{allManualsLoadingMore && [1, 2, 3].map((el) => <ManualCardSmallSkeleton key={el} />)}

				{!loading && pageNumber === lastPage && <p className='end-of-manuals-topic-list'>END OF MANUALS</p>}
			</AllManualsListContainer>
		</>
	)
}

export default AllManualsList
