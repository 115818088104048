export const getFormattedDate = (date_str: string) => {
	const originalDate = new Date(date_str)

	const formattedDate = new Intl.DateTimeFormat('en-GB', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	}).format(originalDate)

	const [day, month, year] = formattedDate.split(' ')

	return `${day} ${month}, ${year}`
}
