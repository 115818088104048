import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled from 'styled-components'

const ManualCardSmallSkeletonContainer = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px 8px;
	// background: red;

	& > div {
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
`

const ManualCardSmallSkeleton = () => {
	return (
		<ManualCardSmallSkeletonContainer>
			<div>
				<Skeleton width={120} height={20} style={{ marginBottom: '10px' }} />
				<Skeleton width={210} height={24} style={{ marginBottom: '4px' }} />
				<Skeleton width={190} height={24} />
			</div>

			<Skeleton width={80} height={85} />
		</ManualCardSmallSkeletonContainer>
	)
}

export default ManualCardSmallSkeleton
