import styled from 'styled-components'

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 0px;
	width: 100%;
	// height: 54px;
	background: #ffffff;
	border: 1px solid #9f0e02;
	border-radius: 4px;

	font-family: 'Apercu';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	color: #9f0e02;
	margin-top: 48px;

	@media (max-width: 340px) {
		// padding: 16px 20px;
	}

	& > svg {
		margin-left: 8px;
	}
`

type Props = {
	onClick: () => void
}

const LoadMoreManualsButton = ({ onClick }: Props) => {
	return (
		<ButtonContainer onClick={onClick}>
			Load more manuals
			<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M4.375 3.75L10.625 10L4.375 16.25' stroke='#C21901' strokeLinecap='round' strokeLinejoin='round' />
				<path d='M10.625 3.75L16.875 10L10.625 16.25' stroke='#C21901' strokeLinecap='round' strokeLinejoin='round' />
			</svg>
		</ButtonContainer>
	)
}

export default LoadMoreManualsButton
