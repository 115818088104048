import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled from 'styled-components'

const LatestManualSkeletonContainer = styled.div`
	padding: 0 20px 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	& > h5 {
		font-weight: 700;
		font-size: 20px;
		line-height: 140%;
		color: #333333;
		padding: 0 0 16px;
		border-bottom: 1px solid #ddd9d9;
		margin-bottom: 24px;
	}
`

const LatestManualSkeleton = () => {
	return (
		<LatestManualSkeletonContainer>
			<h5>Latest Uploaded Manual</h5>

			<Skeleton height={180} style={{ marginBottom: '16px' }} />
			<Skeleton width={231} height={22} style={{ marginBottom: '12px' }} />
			<Skeleton width={350} height={24} style={{ marginBottom: '4px' }} />
			<Skeleton width={350} height={24} style={{ marginBottom: '24px' }} />
			<Skeleton width={116} height={28} style={{ marginBottom: '20px' }} />
		</LatestManualSkeletonContainer>
	)
}

export default LatestManualSkeleton
