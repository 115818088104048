import styled from 'styled-components'

import { IManual } from '../../../utils/types'

import ManualHeading from '../molecules/ManualHeading'
import { ManualMainImage } from '../atoms/ManualMainImage'
import MemoryTrack from '../molecules/MemoryTrack'
import { ManualBody } from '../molecules/ManualBody'
import EndOfManualNote from '../atoms/EndOfManualNote'

import ScriptureModal from '../../general/ScriptureModal'
import ManualSummary from '../molecules/ManualSummary'
import ExploreMoreManuals from '../molecules/ExploreMoreManuals'
import useManualContent from '../../../hooks/manual-content/manual-content'
import { getFormattedDate } from '../../../utils/dateFormatter'
import React, { useCallback } from 'react'

const ManualContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	@media print {
		visibility: hidden;
	}

	& > .manual-summary {
		margin: 20px;

		& > h5 {
			font-family: 'Apercu';
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 144%;
			margin: 20px 0 10px;
		}

		& > p {
			font-family: 'Apercu';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 36px;
		}
	}

	@media (min-width: 768px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

const SamplePic = styled.figure`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 30px 0;

	& > img {
		width: 100%;
	}

	& > figcaption {
		display: flex;
		flex-direction: row;
		text-align: center;

		font-style: italic;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #6b6b6b;
		margin-top: 12px;
	}
`

interface IManualContentProps {
	manual: IManual
	onBibleTextClick: (text: string) => void
}

const ManualContent: React.FC<IManualContentProps> = ({ manual, onBibleTextClick }) => {
	useManualContent(manual, onBibleTextClick)

	return (
		<ManualContentContainer>
			<ManualHeading
				theme={manual.theme}
				topic={manual.topic}
				lessonNumber={manual.lesson}
				readTime={manual.read_time}
				date={getFormattedDate(manual.manual_date)}
				bibleTexts={manual.text}
				handleBibleTextClick={onBibleTextClick}
			/>

			<ManualMainImage src={manual.header_image} />

			{manual.memory_track !== null && manual.memory_track.length > 11 && <MemoryTrack track={manual.memory_track} />}

			<ManualBody className='manual-body'></ManualBody>

			{/* <SamplePic>
				<img src='/assets/images/sample.png' alt='' />
				<figcaption>Image of a tree in form of a man</figcaption>
			</SamplePic> */}

			{manual.summary !== null && manual.summary !== "'<p><br></p>'" && <ManualSummary summary={manual.summary} />}

			<EndOfManualNote />

			<ExploreMoreManuals />
		</ManualContentContainer>
	)
}

export default React.memo(ManualContent)
