import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { createManualPathName } from '../../../utils/createPathName'
import { getFormattedDate } from '../../../utils/dateFormatter'
import { IManual } from '../../../utils/types'

const LinkStyled = styled(Link)`
	width: 100%;
`

const ManualCardSmallContainer = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px 8px;
	width: 100%;
	// height: 142px;
	border-bottom: 1px solid #eeeeee;

	background: var(--manual-card-bg-color);
	transition: all 0.4s ease-in-out;

	& > img {
		width: 25%;
		height: 91px;
		object-fit: cover;
		border-radius: 3px;
	}

	@media (max-width: 340px) {
		padding: 16px 10px;
	}

	@media (min-width: 768px) {
		// width: 713px;
		/* background: #f7fafc; */
		padding: 8px 60px 16px 36px;
		border-bottom: none;
		margin: 0 0 12px;

		& > img {
			width: 96px;
			height: 94px;
			object-fit: cover;
			border-radius: 3px;
		}
	}

	@media (min-width: 1024px) {
		// width: 906px;
	}
`

const ManualCardSmallContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 70%;

	& > h5 {
		// width: 233px;
		font-family: 'Clearface';
		font-style: normal;
		font-weight: 7;
		font-size: 16px;
		line-height: 144%;
		color: var(--manual-heading-details-color);
	}

	& > a {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #c21901;
		padding: 4px 0;
		display: flex;
		flex-direction: row;
		align-items: center;

		& > svg {
			margin-left: 8px;
		}
	}
`

const ManualCardSmallDetails = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 8px;
	padding: 4px 0;

	& > div {
		display: flex;
		flex-direction: row;
		align-items: center;

		& > svg {
			// margin-right: 4px;

			@media (max-width: 340px) {
				// margin-right: 2px;
			}
		}

		& > p {
			font-family: 'Apercu';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 17px;
			text-transform: uppercase;
			color: var(--manual-heading-details-color);

			@media (max-width: 340px) {
				font-size: 10px;
			}
		}
	}

	& > span {
		display: inline-block;
		min-width: 6px;
		width: 6px;
		min-height: 6px;
		height: 6px;
		border-radius: 50%;
		background: var(--primary-manual-text-color);
		margin: 0 8px;
	}
`

const StyledSVG = styled.svg`
	path,
	g > path {
		stroke: var(--svg-stroke-color);
		fill: var(--svg-fill-color);
	}
`

const ManualCardSmall = (manual: IManual) => {
	const [pathName, setPathName] = useState('')

	useEffect(() => {
		let customPathName = createManualPathName(manual.topic, manual.manual_date, manual.id)
		setPathName(customPathName)
	}, [manual])

	return (
		// the link has to be dynamic, based on the id
		<LinkStyled to={`/manual/${pathName}`} className='test'>
			<ManualCardSmallContainer>
				<ManualCardSmallContent>
					<ManualCardSmallDetails>
						<div>
							<StyledSVG width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5Z'
									strokeMiterlimit='10'
								/>
								<path d='M8 5.5V8H10.5' strokeLinecap='round' strokeLinejoin='round' />
								<path
									d='M5.5 4.25625L5.925 1.9125C5.94559 1.79663 6.0064 1.69174 6.09671 1.61629C6.18703 1.54083 6.30106 1.49966 6.41875 1.5H9.58125C9.69894 1.49966 9.81297 1.54083 9.90329 1.61629C9.9936 1.69174 10.0544 1.79663 10.075 1.9125L10.5 4.25625'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M5.5 11.7438L5.925 14.0875C5.94559 14.2034 6.0064 14.3083 6.09671 14.3837C6.18703 14.4592 6.30106 14.5003 6.41875 14.5H9.58125C9.69894 14.5003 9.81297 14.4592 9.90329 14.3837C9.9936 14.3083 10.0544 14.2034 10.075 14.0875L10.5 11.7438'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</StyledSVG>

							<p>{manual.read_time} mins read</p>
						</div>

						<span></span>

						<div>
							<StyledSVG width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M13 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V3C13.5 2.72386 13.2761 2.5 13 2.5Z'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path d='M11 1.5V3.5' strokeLinecap='round' strokeLinejoin='round' />
								<path d='M5 1.5V3.5' strokeLinecap='round' strokeLinejoin='round' />
								<path d='M2.5 5.5H13.5' strokeLinecap='round' strokeLinejoin='round' />
							</StyledSVG>

							<p>{getFormattedDate(manual.manual_date)}</p>
						</div>
					</ManualCardSmallDetails>
					<h5>{manual.topic}</h5>
				</ManualCardSmallContent>

				<img src={manual.header_image} alt={manual.topic} />
			</ManualCardSmallContainer>
		</LinkStyled>
	)
}

export default ManualCardSmall
