import styled from 'styled-components'

const EndOfManual = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 20px;
	margin: 100px 0 44px;

	& > p {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 120%;
		letter-spacing: 0.2em;
		color: #ada7a7;
		margin: 0 16px;
	}

	& > span {
		flex: 1;
		border-bottom: 1px solid #ada7a7;
	}

	// TABS
	@media (min-width: 768px) {
		width: 713px;
		padding: 4px 0;
		margin: 32px 0 104px;
	}

	@media (min-width: 1024px) {
		width: 906px;
		padding: 4px 0;
	}
`

const EndOfManualNote = () => {
	return (
		<EndOfManual>
			<span></span>
			<p>END OF THE LESSON</p>
			<span></span>
		</EndOfManual>
	)
}

export default EndOfManualNote
