import styled from 'styled-components'

export const ManualMainImage = styled.img`
	width: 100%;
	height: 236px;
	object-fit: cover;
	margin: 23px 0 0;

	@media (min-width: 768px) {
		width: 713px;
		height: 400px;
		margin: 36px 0 0;
	}

	@media (min-width: 1024px) {
		width: 906px;
		height: 500px;
	}
`
