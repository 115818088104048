import { useEffect } from 'react'
import styled from 'styled-components'
import Footer from '../components/general/Footer'
import Nav from '../components/general/Nav'

interface ILayoutProps {
	children: React.ReactNode
}

const LayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow: hidden;
`

const Layout: React.FunctionComponent<ILayoutProps> = ({ children }) => {
	useEffect(() => {
		const disableselect = (e: any) => {
			return false
		}

		// document.onselectstart = disableselect
		// document.onmousedown = disableselect
		// document.ontouchstart = disableselect
		// document.ontouchend = disableselect
		// document.ontouchmove = disableselect
		// document.ontouchcancel = disableselect
	}, [])

	return (
		<LayoutWrapper>
			<Nav />
			{children}
			<Footer />
		</LayoutWrapper>
	)
}

export default Layout
