import styled from 'styled-components'

const PrivacyPolicyHeaderContainer = styled.div`
	width: 100%;
	padding: 120px 25px 10px;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	& > p {
		font-weight: 700;
		font-size: 12px;
		line-height: 144%;
		text-transform: uppercase;
		color: #b95951;
	}

	& > h2 {
		margin-top: 12px;
		font-family: 'Clearface';
		font-style: normal;
		font-weight: 100;
		font-size: 28px;
		line-height: 144%;
		letter-spacing: -0.005em;
		color: #111111;
	}
`

const PrivacyPolicyHeader = () => {
	return (
		<PrivacyPolicyHeaderContainer>
			<p>Last Updated: 30th October, 2022</p>
			<h2>Privacy Policy</h2>
		</PrivacyPolicyHeaderContainer>
	)
}

export default PrivacyPolicyHeader
