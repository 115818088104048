import { useEffect } from 'react'
import { tagBibleVerses } from '../../utils/manual-content-tag-utils'
import { IManual } from '../../utils/types'

const useManualContent = (manual: IManual, onBibleTextClick: (text: string) => void) => {
	useEffect(() => {
		const myContainer = document.querySelector('.manual-body') as HTMLDivElement
		if (myContainer) {
			myContainer.innerHTML = tagBibleVerses(manual.body)
		}
	}, [manual.body])

	useEffect(() => {
		const bibleVerseTagged = document.querySelectorAll('.bible-verse-tag')
		const handleClick = (event: Event) => {
			const target = event.target as HTMLElement
			onBibleTextClick(target.innerHTML)
		}

		bibleVerseTagged.forEach((el) => {
			el.addEventListener('click', handleClick)
		})

		return () => {
			bibleVerseTagged.forEach((el) => {
				el.removeEventListener('click', handleClick)
			})
		}
	}, [manual.body, manual.memory_track, onBibleTextClick])
}

export default useManualContent
