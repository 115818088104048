import styled from 'styled-components'

const PrivacyPolicyBodyContainer = styled.div`
	width: 100%;
	padding: 20px 25px 15px;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: #111111;

	& > h4 {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 144%;
		margin: 30px 0 20px;
		color: #b95951;
	}

	& > p {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 36px;
		margin: 0 0 20px;

		& > a {
			color: #0c467b;
			text-decoration: underline;
		}

		& > span {
			text-decoration: underline;
		}
	}

	& > ul {
		list-style-type: disc;
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 36px;
		margin: 0 0 20px 35px;

		& > li > a {
			color: #0c467b;
			text-decoration: underline;
		}

		& > li > span {
			text-decoration: underline;
		}
	}
`

const PrivacyPolicyBody = () => {
	return (
		<PrivacyPolicyBodyContainer>
			<h4>Introduction</h4>
			<p>
				New & Living Way Church Manuals respects your privacy and is committed to safeguarding your sensitive information. This
				privacy notice ("<strong>Privacy Notice</strong>") is an agreement between you and NLWC and its affiliates, corporate
				parent(s), and subsidiaries (collectively "<strong>Manuals</strong>", "<strong>NLWC Manuals</strong>", "
				<strong>NLWC</strong>" "<strong>us</strong>", "<strong>our</strong>", or "<strong>we</strong>") while accessing and using
				our products or services, including our websites (including <a href='nlwc.church'>nlwc.church</a>), social media pages,
				channels, related applications across any devices and platforms, and any other NLWC online presence (collectively, the
				"Services").
			</p>

			<h4>Personal Information</h4>
			<p>
				As used in this Privacy Notice, “Personal Information” means information that specifically identifies an individual (e.g. a
				name, e-mail address), or information about that individual directly linked to personally identifiable information. Personal
				Information does not include (a) aggregate information, meaning data we collect about your use of the Services or about a
				group or category of services or users, from which individual identities or other Personal Information has been removed or
				(b) deidentified information that cannot be easily linked back to the individual.
			</p>
			<p>
				This Privacy Notice describes our practices for collecting, using, maintaining, protecting, and disclosing your Personal
				Information, as well as the Personal Information we collect as a result of your interactions with NLWC via the website.
			</p>
			<p>
				Please read this notice carefully to understand our policies and practices regarding your information and how we will treat
				it.
			</p>

			<h4>Consent</h4>
			<p>
				By accessing or using the Services, and subscribing to get e-mail notifications via the manuals website, you consent to this
				Privacy Notice and the collection and use of your Personal Information as described below. If you do not agree with our
				policies and practices, you may not be able to access or benefit from certain portions of the Services.
			</p>

			<h4>Scope</h4>
			<p>Please be advised that this Privacy Notice applies to information that we collect:</p>
			<ul>
				<li>when you access or use the Services.</li>
				<li>when we communicate with you through email and other means.</li>
				<li>through third parties that we work with to provide our services.</li>
				<li>by any other means over the course of your access or use of the Services.</li>
			</ul>
			<p>
				This Privacy Notice does not apply to information collected about you in any other way, including through mobile
				applications, content, or websites not operated by NLWC.
			</p>

			<h4>Lawful Basis</h4>
			<p>
				We will only collect your Personal Information (a) when applicable, with your consent or (b) if we have a legitimate
				interest in doing so. If we collect or use your Personal Information based on your consent, we will also notify you of any
				changes and request your further consent as needed.
			</p>

			<h4>How We Collect and Use Your Personal Information</h4>
			<p>
				When you access or use our Services or visit our studio, we collect certain Personal Information from you. We collect this
				information:
			</p>
			<ul>
				<li>Directly from you when you provide it to us.</li>
			</ul>

			<h4>Information You Provide to Us.</h4>
			<p>
				We collect Personal Information directly from you with your consent when you interact with us on the Services. The Personal
				Information you provide to us may include:
			</p>
			<ul>
				<li>
					<span>Contact Information</span> - We collect information that you provide by filling out electronic forms on our
					Services. This may include your name, age, telephone number, e-mail address, address, and other personally identifiable
					information. We may use this information to register you to use our Services, send you newsletters, and otherwise
					provide you with information, products and services.
				</li>
			</ul>
			<p>We do not collect, process, or store payment information.</p>

			<h4>Information We Collect Through Automatic Data Collection Technologies.</h4>
			<p>
				<span>Emails</span> - Our emails contain an embedded tracking code to keep track of when the recipient receives and opens
				the email and when the recipient visits our Services via a link in the email.
			</p>
			<p>
				NLWC tracks the number of users who come to our Services from social networking sites (e.g., Facebook, YouTube or Twitter)
				to achieve our legitimate interest in measuring the effectiveness of our social media ads and posts. If you access our
				Services from a social networking site. Please note that social media sites from which we may receive your Personal
				Information are not controlled or supervised by NLWC. Any questions regarding how the social media site collects or
				processes your Personal Information should be directed to the social media site provider. We may retain this information
				with the information we collect from you directly.
			</p>

			<h4>Other Ways We May Use Your Information</h4>
			<p>We may also use Personal Information that we collect from you or about you:</p>
			<ul>
				<li>To develop and improve our products and services</li>
				<li>To present our Services and its contents to you.</li>
				<li>To provide you with information, products, or services that you request from us.</li>
				<li>To fulfil any other purpose for which you provide it.</li>
				<li>To notify you about changes to our Services or any products or services we offer or provide through it.</li>
				<li>To allow you to participate in interactive features on our Services.</li>
				<li>To protect your privacy and enforce this Privacy Notice.</li>
				<li>To protect our rights and property.</li>
				<li>
					If we believe it is necessary to identify, contact, or bring legal action against persons or entities who may be causing
					injury to you, to NLWC, or to others, or to comply with a law, regulation, legal process or court order.
				</li>
				<li>In any other way, we may describe when you provide the information.</li>
				<li>For any other purpose with your consent.</li>
			</ul>
			<p>
				NLWC may use a cookie or third party web beacon to collect and analyze your Personal Information or your activities on the
				Services (e.g., click stream information, browser type, time and date, subject clicked or scrolled over) to help identify
				offers that may be of interest to you.
			</p>
			<p>
				We may combine information we collect about you through the Services with other information about you that we receive from
				third-party sources. By way of example and not limitation, we may use a change of address or other list services to ensure
				that our records for your account are accurate.
			</p>

			<h4>Disclosure of Your Information</h4>
			<p>
				<span>NLWC will not sell your Personal Information to any third parties</span>. We may disclose Personal Information that we
				collect or you provide as described in this Privacy Notice:
			</p>
			<ul>
				<li>To our subsidiaries and affiliates.</li>
				<li>
					To our advertising and analytics partners, such as Google Analytics. The privacy notice of these third-party companies
					applies to their collection, use and disclosure of your information. These third parties may combine your information
					with the information of other consumers for purposes of conducting these studies and/or analytics. For more information,
					please visit{' '}
					<a href='https://tools.google.com/dlpage/gaoptout' target='_blank' rel='noreferrer'>
						Google Analytics Opt-out Browser Add-on
					</a>
					.
				</li>
				<li>
					To fulfil the purpose for which you provide it. For example, if you give us an email address to use the “send
					notification” feature of our Services, we will send you a notification.
				</li>
				<li>For any other purpose disclosed by us when you provide the information.</li>
				<li>With your consent.</li>
			</ul>
			<p>We may also disclose your Personal Information:</p>
			<ul>
				<li>
					To comply with any court order, law, or legal process, including responding to any government or regulatory request.
				</li>
			</ul>
			<p>
				To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes. If we believe
				disclosure is necessary or appropriate to protect the rights, property, or safety of NLWC, our customers, or others.
			</p>

			<h4>Opting Out of Communications</h4>
			<p>
				We want to communicate with you only if you want to hear from us. You can modify or limit the communications NLWC sends to
				you by clicking the “Preferences” or “Unsubscribe” link at the bottom of any of our emails. If you like, you may use one of
				the following statements in your message to us:
			</p>
			<ul>
				<li>
					I prefer not to receive email advertisements, such as updates regarding products and services, special promotions, or
					upcoming events.
				</li>
			</ul>
			<p>
				I prefer not to receive direct mail advertisements, such as periodic catalogues and mailings regarding products and
				services, special promotions, or upcoming events.
			</p>

			<h4>Data Security</h4>
			<p>
				We have implemented commercially reasonable measures both online and offline to secure your Personal Information from
				accidental loss and from unauthorized access, use, alteration, and disclosure. We maintain physical, electronic, and
				procedural security measures to safeguard your non-public Personal Information. These safeguards include limiting our
				in-house storage of your Personal Information to Contact Information and securing that data with industry-standard
				encryption. We transmit all other Personal Information to a service provider that meets international security standards via
				a secure token for that service provider to store and maintain. We update and test our technology to improve the protection
				of our information about you and to assure the integrity of that information.
			</p>
			<p>
				The safety and security of your information also depend on you. Where we have given you (or where you have chosen) a
				password for access to certain parts of our Services, you are responsible for keeping this password confidential. We ask you
				not to share your password with anyone. Also, remember to log off your account and close your browser window when you have
				finished your visit. This is to ensure that others cannot access your account, especially if you are sharing a computer with
				someone else or are using a computer in a public place. All internet users should take care of how they handle and disclose
				their Personal Information. The User Generated Material and other information you share in public areas may be viewed by any
				user of the Services.
			</p>
			<p>
				Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect
				your Personal Information, we cannot guarantee the security of your Personal Information transmitted to our Services or that
				unauthorized third parties will not defeat our security measures or use nonpublic Personal Information for improper
				purposes. Any transmission of Personal Information is at your own risk. We are not responsible for the circumvention of any
				privacy settings or security measures contained in the Services.
			</p>

			<h4>Third-Party Services & Advertising</h4>
			<p>
				This Privacy Notice applies only to information collected by NLWC. We may provide links to third-party websites from our
				Services as a service to our users, but we have no ability to control, and we are not responsible for, the privacy and data
				collection, use, and disclosure practices of third-party websites. When you click on links that take you to external
				websites, you will be subject to their privacy notices and practices and not ours. We encourage you to review and understand
				the privacy notices of such websites before providing them with any information.
			</p>

			<h4>Changes to Our Privacy Policy</h4>
			<p>
				We may change this Privacy Notice from time to time to ensure its accuracy. If we make changes, the revised notice will be
				posted on this page. Your continued use of our Services or other services after the posting of changes to this Privacy
				Notice will mean you accept these changes. In some cases, we may attempt to contact you using the contact information you
				have provided, in order to give you choices about our using your information in a manner different from that stated at the
				time of collection. Please check back frequently, especially before you provide us with Personal Information, to determine
				the current scope of the notice.
			</p>

			<h4>Unauthorized Use</h4>
			<p>
				If you become aware of any unauthorized submission of information to NLWC, please inform us by contacting us at the contact
				information listed below so that we may delete it.
			</p>

			<h4>Contact Information</h4>
			<p>
				To exercise any of your rights over your Personal Information, ask questions about our collection and use of your Personal
				Information or comment about this Privacy Notice and our privacy practices, contact us at:
			</p>
			<p>
				E-mail: <a href='mailto:gratico.ng@gmail.com'>gratico.ng@gmail.com</a>
			</p>
		</PrivacyPolicyBodyContainer>
	)
}

export default PrivacyPolicyBody
