import { books } from './bibleBooks'

const getBookList = () => {
	return books.map((book) => book.name)
}

const getChapterVerseRegex = () => {
	return '([0-9]{1,3}:[0-9]{1,3}(([-,]?(?:[0-9]{1,3}))*)?)(?=(?:[^><]*<[^<>]*>)*[^><]*$)'
}

const getFullRegex = (bookList: string[]) => {
	const chapterVerseRegex = getChapterVerseRegex()
	return new RegExp('(' + bookList.join('|') + ') ' + chapterVerseRegex, 'gm')
}

const generateIdFromContent = (content: string) => {
	// Remove special characters and spaces, convert to lowercase
	return content
		.replace(/[^\w\s]/gi, '')
		.replace(/\s+/g, '-')
		.toLowerCase()
}

const tagHeadersWithIds = (text: string) => {
	const headerRegex = /<h2>(.*?)<\/h2>/g

	return text.replace(headerRegex, (match, p1) => {
		const id = generateIdFromContent(p1)
		return `<h2 id="${id}">${p1}</h2>`
	})
}

const tagBibleVerses = (text: string) => {
	// Tag <h2> headers with IDs based on their content
	const taggedWithHeaders = tagHeadersWithIds(text)

	const bookList = getBookList()
	const fullRegex = getFullRegex(bookList)
	const replacement = "<span class='bible-verse-tag'>$1 $2</span>"

	const taggedText = taggedWithHeaders.replace(fullRegex, replacement)

	return taggedText
	// return text.replace(fullRegex, replacement)
}

const extractH2ContentWithIds = (text: string) => {
	const headerRegex = /<h2>(.*?)<\/h2>/g
	const headersWithIds: { id: string; content: string }[] = []

	text.replace(headerRegex, (match, p1) => {
		const id = generateIdFromContent(p1)
		headersWithIds.push({ id, content: p1 })
		return match // Return the original match to maintain text integrity
	})

	return headersWithIds
}

export { tagBibleVerses, extractH2ContentWithIds }
