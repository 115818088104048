import { useState } from 'react'
import { toast } from 'react-toastify'
import { subscribeEmail } from '../services/subscription'

export const useSubscribe = () => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [emailInput, setEmailInput] = useState('')

	const handleEmailInputChange = (e: any) => {
		// console.log(e.target.value)
		setEmailInput(e.target.value)
	}

	const handleSubscribe = () => {
		let emailRe = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
		let check = emailInput.match(emailRe)

		if (check) {
			setLoading(true)
			setError(false)

			subscribeEmail(emailInput)
				.then((res) => {
					toast.success('Subscribed successfully!', {
						position: 'bottom-center',
						autoClose: 4000,
						hideProgressBar: true,
						closeOnClick: true,
						draggable: false,
					})
					setLoading(false)
					setEmailInput('')
				})
				.catch((err) => {
					// console.log(err)
					// console.log('An error occured. Try again!')

					toast.error('An error occured. Try again!', {
						position: 'bottom-center',
						autoClose: 4000,
						hideProgressBar: true,
						closeOnClick: true,
						draggable: false,
					})
					setLoading(false)
				})
		} else {
			setError(true)
			setLoading(false)
		}
	}

	return {
		loading,
		error,
		emailInput,
		handleEmailInputChange,
		handleSubscribe,
	}
}
