import { RefTagger } from 'react-reftagger'
import AppRouter from './router/app-router'

function App() {
	return (
		<div className='App'>
			<AppRouter />
			{/* <RefTagger bibleVersion={'KJV'} socialSharing={[]} /> */}
		</div>
	)
}

export default App
