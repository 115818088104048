import { BrowserRouter, Route, Routes } from 'react-router-dom'

import HomePage from '../pages'
import Manual from '../pages/manual'
import PrivacyPolicy from '../pages/privacy-policy'

import ScrollToTop from '../utils/scrollToTop'

function AppRouter() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path='/' element={<HomePage />} />
				{/* <Route path='manual/:id'> */}
				{/* <Route index element={<Manual />} /> */}
				<Route path='manual/:pathName' element={<Manual />} />
				<Route path='privacy-policy' element={<PrivacyPolicy />} />
				{/* </Route> */}
			</Routes>
		</BrowserRouter>
	)
}

export default AppRouter
