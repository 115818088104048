import { useCallback, useState } from 'react'

import ManualContent from './ManualContent'
import ScriptureModal from '../../general/ScriptureModal'
import { IManual } from '../../../utils/types'
import FloatingManualNav from '../../floating-navs/floating-manual-nav'
import useFloatingNavVisibility from '../../../hooks/useFloatingNavVisibility'

const ManualContainer = ({ manual }: { manual: IManual }) => {
	const [isScriptureModalShown, setIsScriptureModalShown] = useState(false)
	const [scriptureTextString, setScriptureTextString] = useState('')

	// const isFloatingNavShown = useFloatingNavVisibility()

	const handleBibleTextClickModal = useCallback((bibleText: string) => {
		setScriptureTextString(bibleText)
		setIsScriptureModalShown(true)
	}, [])

	const closeScriptureModal = useCallback(() => {
		setIsScriptureModalShown(false)
	}, [])

	return (
		<>
			<ManualContent manual={manual} onBibleTextClick={handleBibleTextClickModal} />
			<ScriptureModal scriptureText={scriptureTextString} isOpen={isScriptureModalShown} handleModalClose={closeScriptureModal} />

			{/* <FloatingManualNav manual={manual} isShown={isFloatingNavShown} /> */}
		</>
	)
}

export default ManualContainer
