import styled from 'styled-components'

export const ManualBody = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-style: normal;
	text-align: left;
	color: var(--primary-manual-text-color);
	// padding: 20px;
	margin: 56px 0 0;
	transition: all 0.4s ease-in-out;

	& .bible-verse-tag {
		text-decoration: underline;
		color: var(--bible-verse-tag-color);
	}

	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-o-user-select: none;

	& > samplepic {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 30px 0;

		& > img {
			width: 100%;
			height: auto;
			max-height: 500px;
			object-fit: contain;
		}

		& > figcaption {
			display: flex;
			flex-direction: row;
			text-align: center;

			font-style: italic;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			color: #6b6b6b;
			margin-top: 12px;
		}
	}

	& > img,
	& > p > img {
		width: 100%;
		height: auto;
		max-height: 500px;
		object-fit: contain;
	}

	& > p > a {
		color: #0c467b;
		text-decoration: underline;
	}

	& > h1,
	& > h2,
	& > h3,
	& > h4 {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 144%;
		// margin: 20px 20px 32px;
		margin: 20px 20px 0;
	}

	& > p,
	& > ol,
	& > ul {
		margin: 0 20px;

		& > img {
			// position: absolute;
			width: 100%;
			height: auto;
			max-height: 500px;
			object-fit: contain;
		}
	}

	& > ol,
	& > ul {
		margin-left: 30px;
	}

	& > p,
	& > ol > li,
	& > ul > li {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 36px;
		word-break: break-word;
	}

	& > ol > li,
	& > ul > li {
		margin-top: 20px;

		& > img {
			display: block;
			// left: 0;
			width: 100%;
			margin: 20px 0;
		}
	}

	& > span {
		font-weight: 400;
		font-size: 20px;
		line-height: 35px;
	}

	& > p > strong {
		margin-top: 20px;
	}

	// TABS
	@media (min-width: 768px) {
		width: 713px;
		margin: 64px 0 0;
	}

	// DESKTOP
	@media (min-width: 1024px) {
		width: 906px;
		margin: 80px 0 0;

		& > h1,
		& > h2,
		& > h3,
		& > h4 {
			margin: 20px 0 0;
		}

		p,
		ul {
			margin: 0;
		}
	}
	/* 
	& > img,
	& > p > img {
		width: 100%;
		height: 200px;
		object-fit: cover;
	}

	& > p:has(img) {
		margin: 0;
		width: 100%;
	} */
`
