import { useEffect, useState } from 'react'
import { getAllManuals, getAllManualsByTheme, getAllThemes } from '../services/manuals'
import { IManual } from '../utils/types'

interface IAllManualsByThemes {
	theme: string
	manuals: IManual[]
}

export const useManualLibrary = () => {
	// change active tab
	const [activeTab, setActiveTab] = useState('manual-theme')

	// Themes
	const [themesLoading, setThemesLoading] = useState(true)
	// const [themes, setThemes] = useState<string[]>([])
	const [themesPageNumber, setThemesPageNumber] = useState<number>(1)

	const [allManualsByTheme, setAllManualsByTheme] = useState<IAllManualsByThemes[]>()

	useEffect(() => {
		setThemesLoading(true)

		getAllThemes().then((res) => {
			// setThemes(res.data.themes)

			// get all manuals for each theme
			Promise.all<IAllManualsByThemes[]>(res.data.themes.map((theme: string) => getAllManualsByTheme(theme, 1))).then((result) => {
				setAllManualsByTheme(result.reverse())
				setThemesLoading(false)
			})
		})
	}, [themesPageNumber])

	// All Manuals
	const [allManualsLoading, setAllManualsLoading] = useState(true)
	const [allManualsLoadingMore, setAllManualsLoadingMore] = useState(false)
	const [manuals, setManuals] = useState<IManual[]>([])
	const [lastPage, setLastPage] = useState(4)
	const [manualsPageNumber, setManualsPageNumber] = useState<number>(1)

	useEffect(() => {
		setAllManualsLoadingMore(true)
		getAllManuals(manualsPageNumber).then((res) => {
			setLastPage(res.data.last_page)
			setManuals((manuals) => manuals?.concat(res.data.data))
			setAllManualsLoading(false)
			setAllManualsLoadingMore(false)
		})
	}, [manualsPageNumber])

	return {
		activeTab,
		setActiveTab,
		themesLoading,
		// themes,
		setThemesPageNumber,
		allManualsLoading,
		allManualsLoadingMore,
		manuals,
		lastPage,
		manualsPageNumber,
		setManualsPageNumber,
		allManualsByTheme,
	}
}
