import styled from 'styled-components'

import { IManualHeadingProps } from '../../../utils/types'

const ManualHeadingContainer = styled.div`
	padding: 120px 25px 25px;
	margin: 0;

	@media (min-width: 768px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 713px;
	}

	@media (min-width: 1024px) {
		width: 906px;
	}
`

const SundaySchoolThemeText = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	font-weight: 700;
	font-size: 12px;
	line-height: 144%;
	text-transform: uppercase;
	color: #b95951;

	@media (min-width: 768px) {
		font-size: 14px;
		line-height: 120%;
	}

	@media (min-width: 1024px) {
		font-size: 16px;
	}
`

const ManualTopic = styled.h2`
	margin-top: 12px;
	font-family: 'Clearface';
	font-style: normal;
	font-weight: 100;
	font-size: 28px;
	line-height: 144%;
	letter-spacing: -0.005em;
	color: var(--primary-manual-text-color);
	transition: all 0.4s ease-in-out;

	@media (min-width: 768px) {
		font-size: 36px;
		color: var(--primary-manual-text-color);
		text-align: center;
	}

	@media (min-width: 1024px) {
		font-size: 40px;
	}
`

const ManualDetails = styled.div`
	margin-top: 21px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (min-width: 768px) {
		width: 440px;
	}
`

const ManualDetail = styled.div`
	display: flex;
	flex-direction: column;
	align-items: space-between;
	// margin-right: 20px;
	transition: none;

	& > div {
		display: flex;
		flex-direction: row;
	}

	& > div > svg {
		margin-right: 8px;
	}

	& > div > h6 {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
		color: var(--primary-manual-text-color);
		transition: all 0.4s ease-in-out;
	}

	& > p {
		font-family: 'Clearface';
		font-weight: 100;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.005em;
		color: var(manual-heading-details-color);
		margin-top: 12px;
	}

	@media (min-width: 768px) {
		align-items: center;
		width: 100%;

		& > div {
			align-items: center;
		}

		& > div > svg {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}

		& > div > h6 {
			font-size: 14px;
			line-height: 17px;
		}

		& > p {
			font-size: 16px;
			line-height: 21px;
		}
	}
`

const TextToRead = styled.div`
	margin-top: 25px;
	display: flex;
	flex-direction: row;
	align-items: center;

	& > svg {
		margin-right: 8px;
	}

	& > p {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
		color: var(--primary-manual-text-color);
		transition: all 0.4s ease-in-out;
	}

	@media (min-width: 768px) {
		& > svg {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}

		& > p {
			font-size: 14px;
			line-height: 17px;
		}
	}
`

const BibleTexts = styled.div`
	margin-top: 13px;

	& > span {
		font-family: 'Clearface';
		font-style: normal;
		font-weight: 100;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		letter-spacing: 0.02em;
		text-decoration-line: underline;
		// text-decoration-thickness: 0.5px;
		color: var(--bible-verse-tag-color);
	}

	@media (min-width: 768px) {
		& > span {
			font-size: 16px;
			line-height: 21px;
			text-align: center;
			letter-spacing: 0;
		}
	}
`

const StyledSVG = styled.svg`
	path {
		stroke: var(--svg-stroke-color);
		fill: var(--svg-fill-color);
		transition: all 0.4s ease-in-out;
	}
`

const ManualHeading = ({ theme, topic, lessonNumber, readTime, date, bibleTexts, handleBibleTextClick }: IManualHeadingProps) => {
	return (
		<ManualHeadingContainer>
			<SundaySchoolThemeText>Theme: {theme}</SundaySchoolThemeText>
			<ManualTopic>{topic}</ManualTopic>
			<ManualDetails>
				<ManualDetail>
					<div>
						<StyledSVG width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M13.5 3H2.5C2.22386 3 2 3.22386 2 3.5V11C2 11.2761 2.22386 11.5 2.5 11.5H13.5C13.7761 11.5 14 11.2761 14 11V3.5C14 3.22386 13.7761 3 13.5 3Z'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path d='M10 11.5L12 14' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M6 11.5L4 14' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M8 3V1.5' strokeLinecap='round' strokeLinejoin='round' />
						</StyledSVG>

						<h6>Lesson:</h6>
					</div>
					<p>{lessonNumber}</p>
				</ManualDetail>

				<ManualDetail>
					<div>
						<StyledSVG width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5Z'
								strokeMiterlimit='10'
							/>
							<path d='M8 5.5V8H10.5' strokeLinecap='round' strokeLinejoin='round' />
							<path
								d='M5.5 4.25625L5.925 1.9125C5.94559 1.79663 6.0064 1.69174 6.09671 1.61629C6.18703 1.54083 6.30106 1.49966 6.41875 1.5H9.58125C9.69894 1.49966 9.81297 1.54083 9.90329 1.61629C9.9936 1.69174 10.0544 1.79663 10.075 1.9125L10.5 4.25625'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M5.5 11.7438L5.925 14.0875C5.94559 14.2034 6.0064 14.3083 6.09671 14.3837C6.18703 14.4592 6.30106 14.5003 6.41875 14.5H9.58125C9.69894 14.5003 9.81297 14.4592 9.90329 14.3837C9.9936 14.3083 10.0544 14.2034 10.075 14.0875L10.5 11.7438'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</StyledSVG>

						<h6>Read Time:</h6>
					</div>
					<p>{readTime} Minutes</p>
				</ManualDetail>

				<ManualDetail>
					<div>
						<StyledSVG width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M13 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V3C13.5 2.72386 13.2761 2.5 13 2.5Z'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path d='M11 1.5V3.5' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M5 1.5V3.5' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M2.5 5.5H13.5' strokeLinecap='round' strokeLinejoin='round' />
						</StyledSVG>

						<h6>Manual Date:</h6>
					</div>
					<p>{date}</p>
				</ManualDetail>
			</ManualDetails>

			<TextToRead>
				<StyledSVG width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M3.00001 13.5C2.99919 13.3028 3.03742 13.1074 3.11251 12.925C3.1876 12.7426 3.29806 12.577 3.43751 12.4375C3.57696 12.298 3.74265 12.1876 3.92501 12.1125C4.10737 12.0374 4.3028 11.9992 4.50001 12H13V2H4.50001C4.3028 1.99917 4.10737 2.03741 3.92501 2.1125C3.74265 2.18759 3.57696 2.29805 3.43751 2.4375C3.29806 2.57695 3.1876 2.74264 3.11251 2.925C3.03742 3.10736 2.99919 3.30279 3.00001 3.5V13.5Z'
						stroke='#111111'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path d='M3 13.5V14H12' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
				</StyledSVG>
				<p> Text To Read With Study:</p>
			</TextToRead>

			<BibleTexts>
				{bibleTexts &&
					bibleTexts.map((text: any, idx: number) => (
						<span key={idx} onClick={() => handleBibleTextClick(text)}>
							{text}
							{idx === bibleTexts.length - 1 ? '' : '; '}
						</span>
					))}
			</BibleTexts>
		</ManualHeadingContainer>
	)
}

export default ManualHeading
