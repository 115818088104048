import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchManualById } from '../../services/manuals'
import { IManual } from '../../utils/types'

const useManualData = () => {
	const [loading, setLoading] = useState(false)
	const [manualData, setManualData] = useState<IManual>()
	const { pathName } = useParams()

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			try {
				let custompathNameArray = pathName?.split('-')
				let id = custompathNameArray ? custompathNameArray[custompathNameArray.length - 1] : '14'
				const res = await fetchManualById(id)

				setManualData(res.data)
				localStorage.setItem('currentTheme', res.data.theme)
			} catch (error) {
				console.error('Error fetching manual data:', error)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [pathName])

	return { loading, manualData }
}

export default useManualData
