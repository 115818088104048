import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled from 'styled-components'

const ManualSkeletonContainer = styled.div`
	padding: 120px 24px 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@media (min-width: 1024px) {
		align-items: center;
	}
`

const StyledSkeleton = styled(Skeleton)`
	&.react-loading-skeleton {
		--base-color: var(--skeleton-base-color, #e0e0e0);
		--highlight-color: var(--skeleton-highlight-color, #e0e0e0);
		background-color: var(--skeleton-base-color, #e0e0e0) !important; /* default value for fallback */
		background-image: linear-gradient(
			90deg,
			var(--skeleton-base-color, #e0e0e0),
			var(--skeleton-base-color, #e0e0e0) 25%,
			var(--skeleton-highlight-color, #f5f5f5) 50%,
			var(--skeleton-base-color, #e0e0e0) 75%,
			var(--skeleton-base-color, #e0e0e0)
		);
		background-size: 200% 100%;
		animation: shimmer 2.5s infinite;
	}

	@keyframes shimmer {
		0% {
			background-position: -200% 0;
		}
		100% {
			background-position: 200% 0;
		}
	}
`

const ManualSkeleton = () => {
	return (
		<ManualSkeletonContainer>
			<StyledSkeleton width={260} height={22} style={{ marginBottom: '12px' }} />
			<StyledSkeleton width={300} height={35} style={{ marginBottom: '5px' }} />
			<StyledSkeleton width={300} height={35} style={{ marginBottom: '24px' }} />
			<StyledSkeleton width={376} height={46} style={{ marginBottom: '20px' }} />
			<StyledSkeleton width={174} height={16} style={{ marginBottom: '12px' }} />
			<StyledSkeleton width={311} height={18} style={{ marginBottom: '40px' }} />

			<StyledSkeleton width={376} height={200} style={{ marginBottom: '40px' }} />
			<StyledSkeleton width={174} height={16} style={{ marginBottom: '12px' }} />
			<StyledSkeleton width={350} height={14} style={{ marginBottom: '10px' }} />
			<StyledSkeleton width={350} height={14} style={{ marginBottom: '10px' }} />
			<StyledSkeleton width={350} height={14} style={{ marginBottom: '10px' }} />
			<StyledSkeleton width={350} height={14} style={{ marginBottom: '20px' }} />
		</ManualSkeletonContainer>
	)
}

export default ManualSkeleton
