import Layout from '../layouts/Layout'
import ManualSkeleton from '../components/manual/atoms/ManualSkeleton'

import ManualContainer from '../components/manual/organisms/manual-container'
import useManualData from '../hooks/manual-content/get-manual'

function Manual() {
	const { loading, manualData } = useManualData()

	return (
		<Layout>
			{loading && <ManualSkeleton />}

			{!loading && <>{manualData && <ManualContainer manual={manualData} />}</>}
		</Layout>
	)
}

export default Manual
