// bibleUtils.ts

import { books } from '../bibleBooks'
import { INewVerse } from '../types'

const getNumbersWithinRange = (lo: number, hi: number): number[] => {
	const values = []
	for (let i = lo; i <= hi; i++) {
		values.push(i)
	}
	return values
}

const removeHyphenFromVerses = (verses: string[]): number[] => {
	const versesWithoutHyphen: number[] = []

	verses.forEach((el) => {
		if (/^[0-9]{1,3}-[0-9]{1,3}$/.test(el)) {
			const [loNum, hiNum] = el.split('-').map(Number)
			versesWithoutHyphen.push(...getNumbersWithinRange(loNum, hiNum))
		} else {
			versesWithoutHyphen.push(parseInt(el))
		}
	})

	return versesWithoutHyphen.sort((a, b) => a - b)
}

export interface IBibleTextObj {
	bookId: string
	chapter: string
	verses: number[]
	bookName: string
}

const getVerseDetails = (txt: string) => {
	const txtArray = txt.split(' ').length > 2 ? [`${txt.split(' ')[0]} ${txt.split(' ')[1]}`, txt.split(' ')[2]] : txt.split(' ')

	const selectedBook = txtArray[0]
	const bookObjArr = books.filter((book) => book.name === selectedBook)

	// if (bookObjArr.length === 0) {
	// 	throw new Error(`Book ${selectedBook} not found`)
	// }

	const bookId = bookObjArr[0].id.toString()
	const [chapter, versesUnsplit] = txtArray[1].split(':')
	const verses = removeHyphenFromVerses(versesUnsplit.split(','))

	return { bookId, chapter, verses, bookName: selectedBook } as IBibleTextObj
}

// filterArrayOfObjectsByArray
const filterByOnlySelectedVerses = (arrayOfObjects: INewVerse[], filter: number[]): INewVerse[] => {
	return arrayOfObjects.filter((verse) => filter.includes(Number(verse.v)))
}

export { getNumbersWithinRange, removeHyphenFromVerses, getVerseDetails, filterByOnlySelectedVerses }
