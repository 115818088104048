import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSubscribe } from '../../hooks/useSubscribe'

const FooterContainer = styled.div`
	background: #893124;
	padding: 32px 25px 25px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	& > h6 {
		font-style: normal;
		font-weight: 700;
		font-size: 28px;
		line-height: 124%;
		text-align: center;
		color: #ffffff;
		margin-bottom: 24px;
		max-width: 283px;

		@media (min-width: 768px) {
			max-width: 545px;
			font-size: 32px;
			line-height: 116%;
		}
	}

	& > p {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 144%;
		color: #ffffff;

		@media (min-width: 768px) {
			font-size: 18px;
			line-height: 120%;
		}
	}

	& > div.input-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		& > input {
			background: transparent;
			margin: 48px 0;
			width: 310px;
			text-align: center;
			border: none;
			border-bottom: 2px solid #ffffff;
			padding: 0 0 12px;
			outline: none;

			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: #ffffff;
			opacity: 1;

			@media (min-width: 768px) {
				width: 445px;
				font-size: 18px;
				line-height: 22px;
			}
		}

		& > input::placeholder {
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: #ffffff;
			opacity: 0.6;

			@media (min-width: 768px) {
				font-size: 18px;
				line-height: 22px;
			}
		}

		& > span {
			position: absolute;
			bottom: 20px;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: yellow;
			opacity: 1;
		}
	}

	& > button {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 16px 48px;
		width: 315px;
		background: #ffffff;
		border-radius: 4px;
		outline: none;

		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #b95951;
		margin: 0 0 44px;

		@media (min-width: 768px) {
			width: 545px;
			font-size: 18px;
			line-height: 22px;
		}
	}

	& > a > img {
		width: 112px;
		margin-bottom: 40px;
	}

	& > a.church-website {
		text-decoration: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 16px 12px;
		// border: 1px solid #ffffff;
		border-radius: 4px;

		font-weight: 400;
		font-size: 14px;
		line-height: 128%;
		text-decoration-line: underline;
		color: #ffffff;

		& > svg {
			margin-left: 4px;
		}

		@media (min-width: 768px) {
			width: 459px;
		}
	}
`

const StyledToastContainer = styled(ToastContainer)`
	// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
	&&&.Toastify__toast-container {
		width: auto;
		min-width: 100px;
		margin: 0 auto;
		display: flex;
		align-items: center;
	}
	.Toastify__toast {
		background: white;
		border-radius: 6px;
	}
	.Toastify__toast-body {
	}
	.Toastify__progress-bar {
	}
`

const Footer = () => {
	const { loading, error, emailInput, handleEmailInputChange, handleSubscribe } = useSubscribe()

	return (
		<FooterContainer className='footer-container'>
			<h6>Stay updated with our sunday school manual</h6>
			<p>Be notified when we upload a new manual.</p>

			<div className='input-container'>
				<input type='email' value={emailInput} placeholder='Type your email here' onChange={(e) => handleEmailInputChange(e)} />
				{error && <span>Please, enter a valid email</span>}
			</div>

			<button onClick={handleSubscribe} disabled={loading}>
				{!loading && <>Subscribe</>}
				{loading && (
					<>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							style={{ margin: 'auto', background: 'rgb(255, 255, 255)', display: 'block', shapeRendering: 'auto' }}
							width='20px'
							height='20px'
							viewBox='0 0 100 100'
							preserveAspectRatio='xMidYMid'
						>
							<circle
								cx='50'
								cy='50'
								r='32'
								strokeWidth='8'
								stroke='#b95951'
								strokeDasharray='50.26548245743669 50.26548245743669'
								fill='none'
								strokeLinecap='round'
							>
								<animateTransform
									attributeName='transform'
									type='rotate'
									repeatCount='indefinite'
									dur='1s'
									keyTimes='0;1'
									values='0 50 50;360 50 50'
								></animateTransform>
							</circle>
						</svg>
					</>
				)}
			</button>

			<StyledToastContainer />

			<Link to='/'>
				<img src='/assets/nlwc_logo_white.png' alt='' />
			</Link>

			<Link className='church-website' to='/privacy-policy'>
				Privacy Policy
			</Link>

			<a className='church-website' href='https://nlwc.church' target='_blank' rel='noreferrer'>
				visit church website
				<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M16.875 7.8125V3.125H12.1875' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
					<path d='M11.25 8.75L16.875 3.125' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
					<path
						d='M14.375 11.25V16.25C14.375 16.4158 14.3092 16.5747 14.1919 16.6919C14.0747 16.8092 13.9158 16.875 13.75 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V6.25C3.125 6.08424 3.19085 5.92527 3.30806 5.80806C3.42527 5.69085 3.58424 5.625 3.75 5.625H8.75'
						stroke='white'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</a>
		</FooterContainer>
	)
}

export default Footer
