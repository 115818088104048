import axios from 'axios'

// NLWC --->>> https://shark-app-fwwao.ondigitalocean.app/books/43/chapters/2/verse/KJV/[1,2,3,4,5,6,7,8,9]

// Base URL is injected by proxy in dev and by vercel.json in production
const fetchBibleVerse = async (bookID: string, chapter: string) => {
	return await axios.get(`/books/${bookID}/chapters/${chapter}`)
}

/**
 * NEW METHOD FOR FETCHING BIBLE CHAPTERS - courtesy NLWC Bible API
 * @param bookId
 * @param chapterId
 * @returns
 */
export const fetchBibleChapter = async (bookId: string, chapterId: string) => {
	return await axios.get(`https://bible-api.nlwc.church/books/${bookId}/chapters/${chapterId}/verse/KJV`)
}
