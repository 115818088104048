import { useEffect } from 'react'
import styled from 'styled-components'
import { tagBibleVerses } from '../../../utils/manual-content-tag-utils'

const ManualSummaryWrapper = styled.div`
	// padding: 24px 10px 0;
	// width: 100%;

	@media (min-width: 768px) {
		width: 713px;
	}

	@media (min-width: 1024px) {
		width: 906px;
	}
`

const ManualSummaryContainer = styled.div`
	width: 100%;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 20px;

	& .bible-verse-tag {
		text-decoration: underline;
		color: var(--bible-verse-tag-color);
	}

	& > h5 {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 144%;
		margin: 20px 0 10px;
	}

	& > div.manual--summary {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 36px;
	}

	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-o-user-select: none;

	// TABS
	@media (min-width: 768px) {
		background: #fdefe8;
		border: 1px solid #f4f4f4;
		border-radius: 4px;
		padding: 40px 21px;
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: center;
		margin: 40px 0 0;

		& > h5 {
			display: inline-flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			background: #111111;
			border-radius: 29px;

			font-family: 'Apercu';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 120%;
			color: #ffffff;
			text-transform: uppercase;
			margin: 0 0 28px;
		}

		& > div.manual--summary {
			font-weight: 400;
			font-size: 16px;
			line-height: 32px;
			text-align: center;
		}
	}

	@media (min-width: 1024px) {
		padding: 32px 80px 40px;
	}
`

const ManualSummary = ({ summary }: { summary: string }) => {
	useEffect(() => {
		let myContainer = document.querySelector('.manual--summary') as HTMLDivElement
		myContainer.innerHTML = tagBibleVerses(summary)
	}, [summary])

	return (
		<ManualSummaryWrapper id='conclusion'>
			<ManualSummaryContainer>
				<h5>Conclusion</h5>
				<div className='manual--summary'></div>
			</ManualSummaryContainer>
		</ManualSummaryWrapper>
	)
}

export default ManualSummary
