import React, { createContext, useState, useEffect, ReactNode } from 'react'

interface ThemeContextProps {
	theme: string
	toggleTheme: () => void
}

const ThemeContext = createContext<ThemeContextProps>({
	theme: 'light',
	toggleTheme: () => {},
})

interface ThemeProviderProps {
	children: ReactNode
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
	const [theme, setTheme] = useState<string>('light')

	useEffect(() => {
		const savedTheme = localStorage.getItem('theme') || 'light'
		setTheme('light')
	}, [])

	useEffect(() => {
		document.body.setAttribute('data-theme', theme)
		localStorage.setItem('theme', theme)
	}, [theme])

	const toggleTheme = () => {
		setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
	}

	return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}

export { ThemeContext, ThemeProvider }
