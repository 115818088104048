import { useState, useEffect } from 'react'
import { IManual } from '../../utils/types'

const useCarousel = ({ manuals }: { manuals: IManual[] }) => {
	const [cardViewNum, setCardViewNum] = useState(1)
	const [screenWidth, setScreenWidth] = useState(400)
	const [galleryScroller, setGalleryScroller] = useState<HTMLDivElement | null>(null)
	const [touchPosition, setTouchPosition] = useState<number | null>(null)
	const maxViewNum = Math.min(4, manuals.length)

	useEffect(() => {
		setScreenWidth(window.screen.width)
		setGalleryScroller(document.querySelector('.gallery-scroller') as HTMLDivElement)
	}, [])

	const goLeftNav = () => {
		setCardViewNum((prev) => (prev > 1 ? prev - 1 : prev))
	}

	const goRightNav = () => {
		setCardViewNum((prev) => (prev < 4 ? prev + 1 : prev))
	}

	const handleTouchStart = (e: React.TouchEvent) => {
		const touchDown = e.touches[0].clientX
		setTouchPosition(touchDown)
	}

	const handleTouchMove = (e: React.TouchEvent) => {
		const touchDown = touchPosition

		if (touchDown === null) {
			return
		}

		const currentTouch = e.touches[0].clientX
		const diff = touchDown - currentTouch

		if (diff > 5 && cardViewNum < maxViewNum) {
			goRightNav()
		} else if (diff < -5 && cardViewNum > 1) {
			goLeftNav()
		}

		setTouchPosition(null)
	}

	useEffect(() => {
		if (!galleryScroller) return

		const padding = screenWidth < 340 ? (screenWidth - 300) / 2 : (screenWidth - 328) / 2
		let transformValue = 0

		switch (cardViewNum) {
			case 2:
				transformValue = screenWidth - padding * 2 + 8
				break
			case 3:
				transformValue = (screenWidth - padding * 2) * 2 + 16
				break
			case 4:
				transformValue = (screenWidth - padding * 2) * 3 + 16
				break
			default:
				transformValue = 0
		}

		galleryScroller.style.transform = `translateX(-${transformValue}px)`
	}, [cardViewNum, screenWidth, galleryScroller])

	return { cardViewNum, goLeftNav, goRightNav, handleTouchMove, handleTouchStart }
}

export default useCarousel
