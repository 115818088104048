import styled from 'styled-components'

const HeroContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 100px 21px 0;
	width: 100%;

	& > h1 {
		font-family: 'Clearface';
		font-style: normal;
		font-weight: 100;
		font-size: 28px;
		line-height: 140%;
		letter-spacing: -0.5px;
		color: #111111;
		margin: 0;
	}

	& > h5 {
		margin: 12px 0 65px;
		font-weight: 400;
		font-size: 16px;
		line-height: 158%;
		color: #111111;
	}

	@media (min-width: 768px) {
		align-items: center;

		& > h1 {
			width: 600px;
			text-align: center;
			// font-weight: 7;
			font-size: 36px;
			line-height: 144%;
		}

		& > h5 {
			width: 540px;
			font-weight: 400;
			font-size: 20px;
			line-height: 152%;
			text-align: center;
		}
	}
`

const Hero = () => {
	return (
		<HeroContainer>
			<h1>Welcome to the New &amp; Living Way Church Sunday School Resource</h1>
			<h5>We hope as you join us every sunday, you will find clarity on doctrine and manner of life.</h5>
		</HeroContainer>
	)
}

export default Hero
