import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { createManualPathName } from '../../../utils/createPathName'
import { getFormattedDate } from '../../../utils/dateFormatter'
import { IManual } from '../../../utils/types'

const ManualCardContainer = styled.div<IManualCardOpacityProps>`
	display: flex;
	flex-direction: column;
	flex: 0 0 100%;
	// width: 80%;
	max-width: 328px;
	height: 100%;
	background: #ffffff;
	box-shadow: ${(props) => (props.active ? '0px 6px 60px 4px rgba(0, 0, 0, 0.2)' : 'none')};
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
	filter: ${(props) => (props.active ? 'blur(0)' : 'blur(3px)')};
	margin-right: 8px;
	transition: all 0.2s ease-in-out;

	& > img {
		width: 100%;
		height: 211px;
		object-fit: cover;
	}
`

const ManualCardContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	& > a > h5,
	& > h5 {
		width: 238px;
		font-family: 'Clearface';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 140%;
		text-align: center;
		letter-spacing: -0.005em;
		color: #222222;
		margin: 12px 0 24px;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	& > a {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #c21901;
		padding: 4px 0;
		display: flex;
		flex-direction: row;
		align-items: center;

		& > svg {
			margin-left: 8px;
		}
	}
`

const ManualCardDetails = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 24px 0 0;

	& > div {
		display: flex;
		flex-direction: row;
		align-items: center;

		& > svg {
			margin-right: 4px;
		}

		& > p {
			font-family: 'Apercu';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			text-transform: uppercase;
			color: #111111;
		}
	}

	& > span {
		display: inline-block;
		min-width: 6px;
		width: 6px;
		min-height: 6px;
		height: 6px;
		border-radius: 50%;
		background: #222222;
		margin: 0 16px;
	}
`

interface IManualCardStyleProps extends IManual {
	isActive: boolean
}

interface IManualCardOpacityProps {
	active?: boolean
}

const ManualCard = ({ isActive, ...manual }: IManualCardStyleProps) => {
	const [activeState, setActiveState] = useState(false)
	const [pathName, setPathName] = useState('')

	useEffect(() => {
		let customPathName = createManualPathName(manual.topic, manual.manual_date, manual.id)
		setPathName(customPathName)
	}, [manual])

	useEffect(() => {
		setActiveState(isActive)
	}, [isActive])

	return (
		<ManualCardContainer active={activeState}>
			<img src={manual.header_image} alt={manual.topic} />
			<ManualCardContent>
				<ManualCardDetails>
					<div>
						<svg width='20' height='20' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5Z'
								stroke='#111111'
								strokeMiterlimit='10'
							/>
							<path d='M8 5.5V8H10.5' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
							<path
								d='M5.5 4.25625L5.925 1.9125C5.94559 1.79663 6.0064 1.69174 6.09671 1.61629C6.18703 1.54083 6.30106 1.49966 6.41875 1.5H9.58125C9.69894 1.49966 9.81297 1.54083 9.90329 1.61629C9.9936 1.69174 10.0544 1.79663 10.075 1.9125L10.5 4.25625'
								stroke='#111111'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M5.5 11.7438L5.925 14.0875C5.94559 14.2034 6.0064 14.3083 6.09671 14.3837C6.18703 14.4592 6.30106 14.5003 6.41875 14.5H9.58125C9.69894 14.5003 9.81297 14.4592 9.90329 14.3837C9.9936 14.3083 10.0544 14.2034 10.075 14.0875L10.5 11.7438'
								stroke='#111111'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>

						<p>{manual.read_time} mins read</p>
					</div>

					<span></span>

					<div>
						<svg width='20' height='20' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M13 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V3C13.5 2.72386 13.2761 2.5 13 2.5Z'
								stroke='#111111'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path d='M11 1.5V3.5' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M5 1.5V3.5' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M2.5 5.5H13.5' stroke='#111111' strokeLinecap='round' strokeLinejoin='round' />
						</svg>

						<p>{getFormattedDate(manual.manual_date)}</p>
					</div>
				</ManualCardDetails>

				<Link to={`/manual/${pathName}`}>
					<h5>{manual.topic}</h5>
				</Link>

				<Link to={`/manual/${pathName}`}>
					Take Lesson{' '}
					<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M4.375 3.75L10.625 10L4.375 16.25' stroke='#C21901' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M10.625 3.75L16.875 10L10.625 16.25' stroke='#C21901' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</Link>
			</ManualCardContent>
		</ManualCardContainer>
	)
}

export default ManualCard
