import { useState } from 'react'
import styled from 'styled-components'
import { IManual } from '../../../utils/types'
import ManualCardSmall from '../../general/atoms/ManualCardSmall'
import LoadMoreManualsButton from '../../general/LoadMoreManualsButton'

const TopicManualsListContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	transition: all 0.2s ease-in-out;

	&.topic-manual-view-all {
		background: #fdefe8;
		border-bottom: 1px solid #eda9a3;
	}
`

const ThemeHeaderBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 12px;
	width: 100%;
	background: #ffdecf;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
	position: relative;

	& > svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		transition: all 0.2s ease-in-out;
	}

	& > svg.dropdown-rotate {
		top: 28px;
		transform: rotate(180deg);
	}

	& > p {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 2px 10px;
		width: 35px;
		height: 24px;
		background: #9f0e02;
		border-radius: 24px;

		font-family: 'Apercu';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 140%;
		color: #ffffff;

		margin-right: 12px;
		transition-property: width, height, padding, margin;
		transition-duration: 0.2s;
		transition-timing-function: ease-in-out;
	}

	& > h6 {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 144%;
		color: #111111;
		transition: all 0.2s ease-in-out;
		margin-right: 50px;
		text-transform: capitalize;
	}

	&.theme-header-column {
		flex-direction: column-reverse;
		align-items: flex-start;
		height: auto;
		border-radius: 0;
		border-top: 1px solid #eda9a3;
		padding: 28px 24px 20px;
		background: transparent;

		& > p {
			width: auto;
			height: auto;
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 140%;
			color: #9f0e02;
			background: transparent;
			padding: 4px 0;
			margin-top: 12px;
		}
	}

	@media (min-width: 768px) {
		cursor: pointer;
		padding: 24px 22px 12px;

		&.theme-header-column {
			padding: 28px 40px 20px;
		}
	}
`

const AllTopicsList = styled.div`
	display: flex;
	flex-direction: column;
	// align-items: flex;
	padding: 12px;
	width: 100%;

	& > p.end-of-manuals-topic-list {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
		text-align: center;
		color: #bebcbc;
		margin: 32px 0 8px;
	}

	& > a > div {
		padding: 16px 0px !important;
		background: transparent !important;
	}

	& > div:first-child {
		border-top: 1px solid #eeeeee;
	}

	@media (min-width: 768px) {
		padding: 12px 0;

		& > a > div {
			padding: 8px 60px 16px 36px !important;
		}
	}
`

interface IThemeWithManuals {
	theme: string
	count: number
	manuals: IManual[]
}

const TopicManualsList = ({ theme, count, manuals }: IThemeWithManuals) => {
	const [isViewAllActive, setIsViewAllActive] = useState(false)

	return (
		<TopicManualsListContainer className={isViewAllActive ? 'topic-manual-view-all' : ''}>
			<ThemeHeaderBox className={isViewAllActive ? 'theme-header-column' : ''} onClick={() => setIsViewAllActive(!isViewAllActive)}>
				<p>
					{count} {isViewAllActive && <> {manuals && manuals?.length > 1 ? <>Lessons </> : <>Lesson</>} Taught</>}
				</p>

				<h6>{theme}</h6>

				<svg
					className={isViewAllActive ? 'dropdown-rotate' : ''}
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M16.25 7.5L10 13.75L3.75 7.5' stroke='#111111' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
				</svg>
			</ThemeHeaderBox>

			{isViewAllActive && (
				<AllTopicsList>
					{manuals?.map((manual) => (
						<ManualCardSmall {...manual} key={manual.id} />
					))}

					{/* {!loading && pageNumber !== lastPage && <LoadMoreManualsButton onClick={handlePageChange} />} */}

					<p className='end-of-manuals-topic-list'>END OF MANUALS</p>
				</AllTopicsList>
			)}
		</TopicManualsListContainer>
	)
}

export default TopicManualsList
