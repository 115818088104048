import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import FloatingNav from '../components/general/FloatingNav'
import Layout from '../layouts/Layout'
import ClickCarousel from '../components/home/molecules/ClickCarousel'
import Hero from '../components/home/organisms/Hero'
import LatestManual from '../components/home/organisms/LatestManual'
import ManualLibrary from '../components/home/organisms/ManualLibrary'
import { getAllManuals } from '../services/manuals'

import { isEnteringViewport } from '../utils/checkElementInViewport'
import { IManual } from '../utils/types'
import LatestManualSkeleton from '../components/home/atoms/LatestManualSkeleton'

const HeroBG = styled.div`
	width: 100%;
	background: linear-gradient(180deg, #fff4e8 21.67%, rgba(217, 217, 217, 0) 100%);
`

function HomePage() {
	const [isFloatingNavShown, setIsFloatingNavShown] = useState(false)
	const [manuals, setManuals] = useState<IManual[]>([])

	// make this a util
	useEffect(() => {
		const footer = document.querySelector('.footer-container')

		document.addEventListener(
			'scroll',
			() => (isEnteringViewport(footer) ? setIsFloatingNavShown(false) : setIsFloatingNavShown(true)),
			{
				passive: true,
			}
		)
	}, [])

	useEffect(() => {
		getAllManuals().then((res) => {
			// console.log(res)
			setManuals(res.data.data)
		})
	}, [])

	return (
		<>
			<Layout>
				<HeroBG>
					<Hero />
					{manuals.length > 0 && <LatestManual {...manuals[0]} />}
					{manuals.length === 0 && <LatestManualSkeleton />}
				</HeroBG>

				{/* <FloatingNav isShown={isFloatingNavShown} /> */}

				{manuals.length === 0 && <Skeleton height={695} />}
				{manuals.length > 0 && <ClickCarousel manuals={manuals.slice(1, 5)} />}
				<ManualLibrary />
			</Layout>
		</>
	)
}

export default HomePage
