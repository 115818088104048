import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeContext } from '../../contexts/ThemeContext'

const NavContainer = styled.div<{ isLightTheme: boolean }>`
	position: absolute;
	top: 0;
	padding: 36px 25px;
	height: 100px;
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	& > a > img {
		width: ${(props) => (props.isLightTheme ? '98px' : '125px')};
	}

	& > a {
		margin-left: ${(props) => (props.isLightTheme ? '0' : '-15px')};
	}

	@media (min-width: 768px) {
		width: 904px;
	}

	@media (min-width: 1200px) {
		width: 1126px;
	}
`

const NavMenu = styled.div`
	z-index: 110;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	background: var(--background-color);

	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translateX(100%);
	transition: all 0.2s ease-in-out;

	&.show-menu {
		transform: translateX(0);
	}

	& > svg.menu--close-icon {
		position: absolute;
		top: 31px;
		right: 30px;
	}

	& > div {
		font-family: 'Apercu';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		color: var(--primary-manual-text-color);

		margin-top: 224px;
		width: 100%;
	}

	& > div > a {
		width: 100%;
		max-width: 366px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 32px 11px 16px;
		border-bottom: 1px solid #efefef;
	}
`

const StyledSVG = styled.svg`
	path,
	g > path {
		stroke: var(--primary-manual-text-color);
		fill: var(--svg-fill-color);
	}
`

const Nav = () => {
	const [showMenu, setShowMenu] = useState(false)
	const { theme } = useContext(ThemeContext)

	return (
		<NavContainer isLightTheme={theme === 'light'}>
			<Link to='/'>
				<img src={theme === 'light' ? '/assets/nlwc_logo_black.png' : '/assets/nlwc_logo_white.png'} alt='' />
			</Link>

			<StyledSVG
				onClick={() => setShowMenu(true)}
				width='28'
				height='18'
				viewBox='0 0 28 18'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M1.69995 1H26.8428M1.69995 9H26.8428M1.69995 17H26.8428'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</StyledSVG>

			<NavMenu className={showMenu ? 'show-menu' : ''}>
				<StyledSVG
					className='menu--close-icon'
					onClick={() => setShowMenu(false)}
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<g clipPath='url(#clip0_179_1412)'>
						<path
							d='M13.0593 12L19.2843 5.78435C19.4039 5.63862 19.465 5.45363 19.4558 5.26534C19.4465 5.07705 19.3676 4.89893 19.2343 4.76563C19.101 4.63233 18.9229 4.55338 18.7346 4.54413C18.5463 4.53488 18.3613 4.596 18.2156 4.7156L11.9999 10.9406L5.78432 4.7156C5.6386 4.596 5.4536 4.53488 5.26531 4.54413C5.07702 4.55338 4.89891 4.63233 4.7656 4.76563C4.6323 4.89893 4.55335 5.07705 4.5441 5.26534C4.53486 5.45363 4.59597 5.63862 4.71557 5.78435L10.9406 12L4.71557 18.2156C4.57474 18.3578 4.49573 18.5498 4.49573 18.75C4.49573 18.9501 4.57474 19.1422 4.71557 19.2843C4.85893 19.423 5.05054 19.5004 5.24995 19.5004C5.44936 19.5004 5.64097 19.423 5.78432 19.2843L11.9999 13.0593L18.2156 19.2843C18.3589 19.423 18.5505 19.5004 18.7499 19.5004C18.9494 19.5004 19.141 19.423 19.2843 19.2843C19.4252 19.1422 19.5042 18.9501 19.5042 18.75C19.5042 18.5498 19.4252 18.3578 19.2843 18.2156L13.0593 12Z'
							fill='#4A4949'
						/>
					</g>
					<defs>
						<clipPath id='clip0_179_1412'>
							<rect width='24' height='24' fill='white' />
						</clipPath>
					</defs>
				</StyledSVG>

				<div>
					<a href='https://nlwc.church/' target='_blank' rel='noreferrer'>
						About NLWC{' '}
						<StyledSVG width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M15.5 10.8333V15.8333C15.5 16.2754 15.3244 16.6993 15.0118 17.0118C14.6993 17.3244 14.2754 17.5 13.8333 17.5H4.66667C4.22464 17.5 3.80072 17.3244 3.48816 17.0118C3.17559 16.6993 3 16.2754 3 15.8333V6.66667C3 6.22464 3.17559 5.80072 3.48816 5.48816C3.80072 5.17559 4.22464 5 4.66667 5H9.66667'
								strokeWidth='1.5'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path d='M13 2.5H18V7.5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M8.83337 11.6667L18 2.5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
						</StyledSVG>
					</a>
					<a href='https://nlwc.church/audio-messages/' target='_blank' rel='noreferrer'>
						Listen to our audio messages
						<StyledSVG width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M15.5 10.8333V15.8333C15.5 16.2754 15.3244 16.6993 15.0118 17.0118C14.6993 17.3244 14.2754 17.5 13.8333 17.5H4.66667C4.22464 17.5 3.80072 17.3244 3.48816 17.0118C3.17559 16.6993 3 16.2754 3 15.8333V6.66667C3 6.22464 3.17559 5.80072 3.48816 5.48816C3.80072 5.17559 4.22464 5 4.66667 5H9.66667'
								strokeWidth='1.5'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path d='M13 2.5H18V7.5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M8.83337 11.6667L18 2.5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
						</StyledSVG>
					</a>
					<a href='https://nlwc.church/contact/' target='_blank' rel='noreferrer'>
						Contact Us
						<StyledSVG width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M15.5 10.8333V15.8333C15.5 16.2754 15.3244 16.6993 15.0118 17.0118C14.6993 17.3244 14.2754 17.5 13.8333 17.5H4.66667C4.22464 17.5 3.80072 17.3244 3.48816 17.0118C3.17559 16.6993 3 16.2754 3 15.8333V6.66667C3 6.22464 3.17559 5.80072 3.48816 5.48816C3.80072 5.17559 4.22464 5 4.66667 5H9.66667'
								strokeWidth='1.5'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path d='M13 2.5H18V7.5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
							<path d='M8.83337 11.6667L18 2.5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
						</StyledSVG>
					</a>
				</div>
			</NavMenu>
		</NavContainer>
	)
}

export default Nav
