import { useEffect } from 'react'
import styled from 'styled-components'
import { tagBibleVerses } from '../../../utils/manual-content-tag-utils'

const MemoryTrackWrapper = styled.div`
	padding: 24px 10px 0;
	width: 100%;

	@media (min-width: 768px) {
		width: 713px;
		padding: 32px 0 0;
	}

	@media (min-width: 1024px) {
		width: 906px;
		padding: 24px 0 0;
	}
`
const MemoryTrackContainer = styled.div`
	width: 100%;
	padding: 28px 38px 28px 20px;
	background: #e4f0fe;
	border: 1px solid #f4f4f4;
	border-radius: 4px;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;

	@media (min-width: 768px) {
		padding: 40px 21px;
		align-items: center;
	}

	@media (min-width: 1024px) {
		padding: 47px 32px;
	}
`

const MemoryTrackHeader = styled.div`
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 20px;
	background: #111111;
	border-radius: 29px;

	font-family: 'Apercu';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	color: #ffffff;
	text-transform: uppercase;
`

const MemoryTrackContent = styled.div`
	margin: 16px 0 12px;
	width: 100%;
	font-family: 'Apercu';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 200%;
	color: #333333;

	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-o-user-select: none;

	& .bible-verse-tag {
		text-decoration: underline;
		color: var(--bible-verse-tag-color);
	}

	& > ol,
	& > ul {
		text-align: start;
		padding: 10px 30px;
	}

	@media (min-width: 768px) {
		margin: 24px 0 12px;
		text-align: center;
	}
`

const MemoryTrack = ({ track }: { track: string }) => {
	useEffect(() => {
		let myContainer = document.querySelector('.manual--memory-track-content') as HTMLDivElement
		myContainer.innerHTML = tagBibleVerses(track)
	}, [track])

	return (
		<MemoryTrackWrapper id='memory-track'>
			<MemoryTrackContainer>
				<MemoryTrackHeader>Memory Track</MemoryTrackHeader>
				<MemoryTrackContent className='manual--memory-track-content'></MemoryTrackContent>
			</MemoryTrackContainer>
		</MemoryTrackWrapper>
	)
}

export default MemoryTrack
